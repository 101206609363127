<template>
	<div class="mb-14">
		<nav class="select-none bg-blue-900 fixed top-0 z-50 w-full">
			<div class="max-w-full mx-auto px-4 md:px-16">
				
				<div class="relative flex items-center justify-between h-14">
					<div
						class="absolute w-full inset-y-0 left-0 flex justify-between items-center md:hidden">
						<div
							@click.stop="routeToHome"
							class="cursor-pointer flex-shrink-0 flex items-center">
							<LogoSendXArrow class="h-7 w-auto" />
						</div>
						<!-- Mobile menu button-->
						<button
							@click="mobileMenuOpen = !mobileMenuOpen"
							type="button"
							class="flex items-center justify-center pl-2 rounded-md text-gray-200 hover:text-white focus:outline-none focus:ring-transparent focus:ring-offset-transparent"
							aria-controls="mobile-menu"
							aria-expanded="false">
							<span class="sr-only">Open main menu</span>
							<svg
								v-if="!mobileMenuOpen"
								class="h-7 w-7"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true">
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M4 6h16M4 12h16M4 18h16" />
							</svg>
							<!--
                  Icon when menu is open.
  
                  Heroicon name: outline/x
  
                  Menu open: "block", Menu closed: "hidden"
                -->
							<svg
								v-else
								class="h-7 w-7"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true">
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					</div>
					<div
						class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
						<div class="hidden md:flex flex-row mr-2">
							<div
								@click.stop="routeToHome"
								class="mr-5 cursor-pointer flex-shrink-0 flex items-center">
								<LogoSendXArrow class="h-7 w-auto" />
							</div>
							<div
								v-click-outside="hideDropdowns"
								class="flex relative">
								<div
									@click.stop="hideDropdownsAndRouteToDashboard"
									class="cursor-pointer active-tab flex h-14 items-center text-gray-200 hover:text-white px-3 text-sm">
									<span>Dashboard</span>
								</div>
								<div
									@click.stop="toggleDropdown(dropdowns.Campaigns)"
									:class="
										$route.fullPath.includes('/campaigns')
											? 'nuxt-link-active'
											: ''
									"
									class="active-tab flex h-14 items-center cursor-pointer text-gray-200 hover:text-white px-3 text-sm">
									<span class="flex flex-row items-center space-x-1">
										<span>Campaigns</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="h-4 w-4"
											viewBox="0 0 20 20"
											fill="currentColor">
											<path
												fill-rule="evenodd"
												d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
												clip-rule="evenodd" />
										</svg>
									</span>
									<Transition
										enter-active-class="transition ease-out duration-100"
										enter-from-class="transform opacity-0 scale-95"
										enter-to-class="transform opacity-100 scale-100"
										leave-active-class="transition ease-in duration-75"
										leave-from-class="transform opacity-100 scale-100"
										leave-to-class="transform opacity-0 scale-95">
										<div
											v-if="activeDropdown === dropdowns.Campaigns">
											<NavDropdownCampaigns
												class="absolute z-30 -ml-28 mt-6" />
										</div>
									</Transition>
								</div>
								<div
									@click.stop="toggleDropdown(dropdowns.Audience)"
									:class="
										$route.fullPath.includes('/audience')
											? 'nuxt-link-active'
											: ''
									"
									class="active-tab flex h-14 items-center cursor-pointer text-gray-200 hover:text-white px-3 text-sm">
									<span class="flex flex-row items-center space-x-1">
										<span>Audience</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="h-4 w-4"
											viewBox="0 0 20 20"
											fill="currentColor">
											<path
												fill-rule="evenodd"
												d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
												clip-rule="evenodd" />
										</svg>
									</span>
									<Transition
										enter-active-class="transition ease-out duration-100"
										enter-from-class="transform opacity-0 scale-95"
										enter-to-class="transform opacity-100 scale-100"
										leave-active-class="transition ease-in duration-75"
										leave-from-class="transform opacity-100 scale-100"
										leave-to-class="transform opacity-0 scale-95">
										<div
											v-if="activeDropdown === dropdowns.Audience"
											class="z-50">
											<NavDropdownAudience
												class="absolute z-30 -ml-28 mt-6" />
										</div>
									</Transition>
								</div>
								<nuxt-link
									:to="$config.public.routes.Form.Popup"
									class="flex h-14 items-center text-gray-200 hover:text-white px-3 text-sm">
									<span
										class="cursor-pointer active-tab flex h-14 items-center text-gray-200 hover:text-white px-3 text-sm">
										<span>Forms</span>
									</span>
								</nuxt-link>
								<!-- <nuxt-link :to="$config.public.routes.Workflow.Home" class="flex h-14 items-center text-gray-200 hover:text-white px-3 text-sm">
                    <span class="cursor-pointer active-tab flex h-14 items-center text-gray-200 hover:text-white px-3 text-sm">
                      <span>Workflows</span>
                    </span>
                  </nuxt-link> -->
								<nuxt-link
									:to="$config.public.routes.Automation.Home"
									class="flex h-14 items-center text-gray-200 hover:text-white px-3 text-sm">
									<span
										class="cursor-pointer active-tab flex h-14 items-center text-gray-200 hover:text-white px-3 text-sm">
										<span>Automations</span>
									</span>
								</nuxt-link>
								<nuxt-link
									:to="$config.public.routes.Workflow.Home"
									class="flex h-14 items-center text-gray-200 hover:text-white px-3 text-sm">
									<span
										class="cursor-pointer active-tab flex h-14 items-center text-gray-200 hover:text-white px-3 text-sm">
										<span>Workflows</span>
									</span>
								</nuxt-link>
								<nuxt-link
									v-if="store.$state.authInfo?.account?.IsAdmin"
									:to="$config.public.routes.Admin"
									class="flex h-14 items-center text-gray-200 hover:text-white px-3 text-sm">
									<span
										class="cursor-pointer active-tab flex h-14 items-center text-gray-200 hover:text-white px-3 text-sm">
										<span>Admin</span>
									</span>
								</nuxt-link>
							</div>
						</div>
					</div>
					<div
						class="hidden md:flex absolute inset-y-0 right-0 items-center pr-2 sm:static sm:inset-auto sm:pr-0">
						<button
							v-if="
								settingStore.$state.accountState ===
									AccountState.CardInvalid && isAccountPaid
							"
							@click.stop="upgradeClicked"
							type="button"
							class="hidden lg:inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
							<span>Invalid Card</span>
						</button>
						<div v-else-if="isAccountPaid"></div>
						<button
							v-else-if="
								store.checkVisibilitity({ entity: AppEntities.Billing })
							"
							@click.stop="upgradeClicked"
							type="button"
							class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-emerald-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-emerald-500 focus:outline-none focus:border-emerald-700 focus:shadow-outline-emerald transition ease-in-out duration-150 sm:text-sm sm:leading-5">
							<span>Upgrade</span>
						</button>
						<div class="h-14 border-l border-white opacity-10 ml-3"></div>
						<div class="flex flex-row items-center space-x-3 pl-3">
							<button
								v-if="
									store.checkVisibilitity({
										entity: AppEntities.Contact,
									})
								"
								@click.stop="videoSearchClicked"
								type="button"
								class="p-1 rounded-full text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
								<span class="sr-only">Video Tutorial</span>
								<!-- Heroicon name: outline/bell -->
								<svg
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									width="35"
									height="35"
									viewBox="0 0 48 48">
									<path
										fill="#fff"
										d="M43.102,33.9c-0.399,2.1-2.095,3.7-4.19,4C35.621,38.4,30.135,39,23.95,39	c-6.085,0-11.571-0.6-14.963-1.1c-2.095-0.3-3.791-1.9-4.19-4C4.399,31.6,4,28.2,4,24s0.399-7.6,0.798-9.9	c0.399-2.1,2.095-3.7,4.19-4C12.279,9.6,17.766,9,23.95,9s11.571,0.6,14.963,1.1c2.095,0.3,3.791,1.9,4.19,4	C43.501,16.4,44,19.8,44,24C43.9,28.2,43.501,31.6,43.102,33.9z"></path>
									<path
										fill="#1e3a8a"
										d="M34,21c-0.33,0.17-0.67,0.33-1,0.5c-1.33,0.67-2.67,1.33-4,2c-1.67,0.83-3.34,1.66-5,2.5 c-1.66-0.84-3.33-1.67-5-2.5c-1.67-0.83-3.34-1.66-5-2.5c3.33-1.33,6.67-2.67,10-4C27.33,18.33,30.67,19.67,34,21z"></path>
									<path
										fill="#1e3a8a"
										d="M29,23v0.5c-1.67,0.83-3.34,1.66-5,2.5c-1.66-0.84-3.33-1.67-5-2.5V23H29z"></path>
									<path
										fill="#1e3a8a"
										d="M24.89,27.79L29,25.74c0,1.753,0,3.507,0,5.26c-3.333,0-6.667,0-10,0c0-1.753,0-3.507,0-5.26	l4.11,2.05L24,28.24L24.89,27.79z"></path>
									<path
										fill="none"
										stroke="#1e3a8a"
										stroke-miterlimit="10"
										stroke-width="2"
										d="M33,21c0,0.17,0,0.33,0,0.5c0,0.75,0,1.49,0,2.24	c0,1.09,0,2.17,0,3.26"></path>
								</svg>
							</button>
							<button
								v-if="
									store.checkVisibilitity({
										entity: AppEntities.Contact,
									})
								"
								@click.stop="contactSearchClicked"
								type="button"
								class="p-1 rounded-full text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
								<span class="sr-only">Search</span>
								<!-- Heroicon name: outline/bell -->
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-5 w-5"
									viewBox="0 0 20 20"
									fill="currentColor">
									<path
										fill-rule="evenodd"
										d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
										clip-rule="evenodd" />
								</svg>
							</button>
							<button
								type="button"
								class="p-1 rounded-full text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white beamerTrigger">
								<span class="sr-only">View notifications</span>
								<!-- Heroicon name: outline/bell -->
								<svg
									class="h-5 w-5"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true">
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
								</svg>
							</button>
							<!-- <button
                type="button"
                class="p-1 rounded-full text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              >
                <span class="sr-only">Other apps</span>
                <svg
                  class="h-4 w-4"
                  viewBox="0 0 17 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.129883 4H4.12988V0H0.129883V4ZM6.12988 16H10.1299V12H6.12988V16ZM0.129883 16H4.12988V12H0.129883V16ZM0.129883 10H4.12988V6H0.129883V10ZM6.12988 10H10.1299V6H6.12988V10ZM12.1299 0V4H16.1299V0H12.1299ZM6.12988 4H10.1299V0H6.12988V4ZM12.1299 10H16.1299V6H12.1299V10ZM12.1299 16H16.1299V12H12.1299V16Z"
                    fill="white"
                  />
                </svg>
              </button> -->
						</div>

						<div class="h-14 border-l border-white opacity-10 ml-3"></div>

						<!-- Profile dropdown -->
						<div
							v-click-outside="closeDropDown"
							class="pl-3 relative">
							<div>
								<button
									@click.stop="
										profileDropDownOpen = !profileDropDownOpen;
										switchDropDownOpen = false;
									"
									type="button"
									class="bg-white flex text-sm rounded-full focus:outline-none focus:ring-transparent focus:ring-offset-transparent"
									id="user-menu-button"
									aria-expanded="false"
									aria-haspopup="true">
									<span class="sr-only">Open user menu</span>
									<div class="relative">
										<div
											class="h-10 w-10 flex flex-col justify-center items-center p-2 bg-blue-500 rounded-full">
											<h1 class="text-white text-sm">
												{{ getInitials(currentTeam?.name) }}
											</h1>
										</div>
										<img
											class="absolute border-2 border-gray-50 h-3 w-3 rounded-full bottom-0 -right-[1px]"
											:src="
												store.$state.memberAvatarUrl != ''
													? store.$state.memberAvatarUrl
													: tempAvatarUrl
											"
											alt="" />
									</div>
								</button>
							</div>

							<NavbarProfileDropdown
								:profile-drop-down-open="profileDropDownOpen"
								:is-account-paid="isAccountPaid"
								:activeTeam="currentTeam"
								@open-beamer-ideas="openBeamerIdeas"
								@open-beamer-roadmap="openBeamerRoadmap"
								@switchTeamClicked="switchTeamClicked"
								@closeDropDown="closeDropDown" />

							<transition
								enter-active-class="transition ease-out duration-100"
								enter-class="transform opacity-0 scale-95"
								enter-to-class="transform opacity-100 scale-100"
								leave-active-class="transition ease-in duration-75"
								leave-class="transform opacity-100 scale-100"
								leave-to-class="transform opacity-0 scale-95">
								<div
									v-if="switchDropDownOpen"
									class="z-20 origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
									role="menu"
									aria-orientation="vertical"
									aria-labelledby="user-menu-button"
									tabindex="-1">
									<div class="h-[40vh] overflow-y-auto">
										<div v-for="(sa, ind) in memTeams">
											<div
												@click.stop="switchTeam(sa.id, sa.name)"
												class="flex flex-col divide-y divide-y-gray-50 cursor-pointer">
												<div class="relative group">
													<div
														class="flex justify-between"
														:class="[
															sa.id === currentTeam?.id
																? 'bg-green-200'
																: 'hover:bg-gray-100',
														]">
														<div
															class="px-4 py-2 flex space-x-4 items-center">
															<div>
																<!-- <span class="inline-block relative">
                                  <img class="h-10 w-10 rounded-full"
                                    :class="[sa.id === activeTeam?.id ? 'border-2 border-blue-900' : '']"
                                    :src="sa.avatarUrl != '' ? sa.avatarUrl : tempAvatarUrl" alt="">
                                  <span class="absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white"
                                    :class="[sa.id === activeTeam?.id ? 'bg-green-400' : 'bg-red-400']"></span>
                                </span> -->
															</div>
															<div
																class="flex flex-col justify-between">
																<div
																	class="text-gray-800 leading-5"
																	:class="[
																		sa.id === currentTeam?.id
																			? 'font-semibold text-md'
																			: 'text-sm font-medium',
																	]">
																	{{ sa.name }}
																</div>
																<div
																	class="text-gray-400 leading-5 text-xs"
																	:class="[
																		sa.id === currentTeam?.id
																			? 'font-semibold'
																			: 'font-medium',
																	]">
																	{{
																		sa.companyName !== ""
																			? sa.companyName
																			: "-"
																	}}
																</div>
															</div>
														</div>
														<div
															v-if="
																store.checkVisibilitity({
																	entity: AppEntities.Team,
																}) &&
																sa.id !== currentTeam?.id &&
																sa.type !== 0
															"
															@click.stop="teamMenuCliked(ind)"
															class="text-black mr-2 flex items-center">
															<svg
																class="h-6 w-6"
																viewBox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg">
																<g
																	id="SVGRepo_bgCarrier"
																	stroke-width="0"></g>
																<g
																	id="SVGRepo_tracerCarrier"
																	stroke-linecap="round"
																	stroke-linejoin="round"></g>
																<g id="SVGRepo_iconCarrier">
																	<path
																		d="M12 12H12.01M12 6H12.01M12 18H12.01M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18ZM13 6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6Z"
																		stroke="#000000"
																		stroke-width="2"
																		stroke-linecap="round"
																		stroke-linejoin="round"></path>
																</g>
															</svg>
														</div>

														<transition
															enter-active-class="transition ease-out duration-100"
															enter-class="transform opacity-0 scale-95"
															enter-to-class="transform opacity-100 scale-100"
															leave-active-class="transition ease-in duration-75"
															leave-class="transform opacity-100 scale-100"
															leave-to-class="transform opacity-0 scale-95">
															<div
																v-if="ind === teamMenuOpenId"
																class="z-20 origin-top-right absolute right-0 mb-2 mt-12 w-36 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
																role="menu"
																aria-orientation="vertical"
																aria-labelledby="user-menu-button"
																tabindex="-1">
																<div
																	v-if="
																		store.checkVisibilitity({
																			entity:
																				AppEntities.Team,
																		})
																	">
																	<div
																		@click.stop="
																			deleteTeamClicked(
																				sa.id
																			)
																		"
																		class="py-1 px-2 hover:bg-gray-100 text-sm text-red-500">
																		Delete
																	</div>
																</div>
															</div>
														</transition>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- <div>
                    <button
                      @click.stop.stop.prevent="addTeamClicked"
                      type="button"
                      class="hover:bg-gray-100 inline-flex items-center justify-center px-4 py-2 w-full border border-transparent shadow-sm text-sm font-semibold rounded-md text-gray-800 bg-white outline-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="-ml-1 mr-2 h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Add Team
                    </button>
                  </div> -->
								</div>
							</transition>

							<!-- <transition
								enter-active-class="transition ease-out duration-100"
								enter-class="transform opacity-0 scale-95"
								enter-to-class="transform opacity-100 scale-100"
								leave-active-class="transition ease-in duration-75"
								leave-class="transform opacity-100 scale-100"
								leave-to-class="transform opacity-0 scale-95">
								<div
									v-if="searchDropDownOpen"
									v-click-outside="closeContactSearch"
									class="z-20 origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
									tabindex="-1"
									role="listbox"
									aria-labelledby="listbox-label"
									ref="optionsRef">
									<div class="p-2.5 flex items-center">
										<input
											v-model="searchValue"
											type="text"
											autocomplete="off"
											data-lpignore="true"
											name="firstName"
											id="firstName"
											class="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
											placeholder="Search contact by email ..." />

										<svg
											@click.stop.stop="searchContact"
											class="ml-2 w-8 h-8 cursor-pointer"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<g
												id="SVGRepo_bgCarrier"
												stroke-width="0"></g>
											<g
												id="SVGRepo_tracerCarrier"
												stroke-linecap="round"
												stroke-linejoin="round"></g>
											<g id="SVGRepo_iconCarrier">
												<path
													d="M11.5003 12H5.41872M5.24634 12.7972L4.24158 15.7986C3.69128 17.4424 3.41613 18.2643 3.61359 18.7704C3.78506 19.21 4.15335 19.5432 4.6078 19.6701C5.13111 19.8161 5.92151 19.4604 7.50231 18.7491L17.6367 14.1886C19.1797 13.4942 19.9512 13.1471 20.1896 12.6648C20.3968 12.2458 20.3968 11.7541 20.1896 11.3351C19.9512 10.8529 19.1797 10.5057 17.6367 9.81135L7.48483 5.24303C5.90879 4.53382 5.12078 4.17921 4.59799 4.32468C4.14397 4.45101 3.77572 4.78336 3.60365 5.22209C3.40551 5.72728 3.67772 6.54741 4.22215 8.18767L5.24829 11.2793C5.34179 11.561 5.38855 11.7019 5.407 11.8459C5.42338 11.9738 5.42321 12.1032 5.40651 12.231C5.38768 12.375 5.34057 12.5157 5.24634 12.7972Z"
													stroke="#000000"
													stroke-width="2"
													stroke-linecap="round"
													stroke-linejoin="round"></path>
											</g>
										</svg>
									</div>
									<ul class="mt-2">
										<li
											v-if="queryInProgress"
											class="font-semibold px-3 pb-1 text-md">
											Loading Contacts ...
										</li>
										<li
											v-else-if="
												!queryInProgress &&
												filteredContact?.length > 0 &&
												filteredContact?.[0].id > 0
											"
											class="cursor-pointer select-none px-2 text-sm"
											role="option">
											<span
												@click.stop.stop="
													gotoContact(filteredContact?.[0].id)
												"
												class="font-semibold block truncate shadow-sm w-full pl-4 mx-2 py-2 hover:shadow-md rounded-md">
												{{ filteredContact?.[0].email }}
											</span>
										</li>
										<li
											v-else-if="
												!queryInProgress &&
												filteredContact?.[0].id === 0
											"
											class="font-semibold px-3 pb-1 text-md">
											No contacts found
										</li>
									</ul>
								</div>
							</transition> -->
						</div>
					</div>
				</div>
			</div>

			<!-- Mobile menu, show/hide based on menu state. -->
			<transition
				v-click-outdside="closeMobileMenu"
				enter-active-class="transition ease-out duration-100"
				enter-class="transform opacity-0 scale-95"
				enter-to-class="transform opacity-100 scale-100"
				leave-active-class="transition ease-in duration-75"
				leave-class="transform opacity-100 scale-100"
				leave-to-class="transform opacity-0 scale-95">
				<div
					v-if="mobileMenuOpen"
					class="absolute bg-blue-900 w-full z-20 md:hidden"
					id="mobile-menu">
					<div
						class="w-fit px-2 pt-2 pb-3 flex flex-col space-y-1 border-b border-blue-800">
						<nuxt-link
							@click.stop="closeMobileMenu"
							exact
							:to="$config.public.routes.Dashboard"
							class="w-fit active-tab text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-base font-medium">
							Dashboard
						</nuxt-link>
						<nuxt-link
							@click.stop="toggleDropdown(dropdowns.Campaigns)"
							:to="$config.public.routes.Campaign.Newsletter.Home"
							class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium">
							<span class="flex flex-row items-center space-x-1">
								<span>Campaigns</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4"
									viewBox="0 0 20 20"
									fill="currentColor">
									<path
										fill-rule="evenodd"
										d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
										clip-rule="evenodd" />
								</svg>
							</span>
						</nuxt-link>
						<Transition
							enter-active-class="transition ease-out duration-100"
							enter-from-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-from-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95">
							<div v-if="activeDropdown === dropdowns.Campaigns">
								<NavDropdownCampaigns
									@click.stop="closeMobileMenu"
									class="absolute z-30 -ml-0 mt-1" />
							</div>
						</Transition>
						<a
							@click.stop="toggleDropdown(dropdowns.Audience)"
							href="#"
							class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium">
							<span class="flex flex-row items-center space-x-1">
								<span>Audience</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4"
									viewBox="0 0 20 20"
									fill="currentColor">
									<path
										fill-rule="evenodd"
										d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
										clip-rule="evenodd" />
								</svg>
							</span>
						</a>
						<Transition
							enter-active-class="transition ease-out duration-100"
							enter-from-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-from-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95">
							<div
								v-if="activeDropdown === dropdowns.Audience"
								class="z-50">
								<NavDropdownAudience
									@click.stop="closeMobileMenu"
									class="absolute z-30 -ml-0 mt-1" />
							</div>
						</Transition>
						<nuxt-link
							@click.stop="closeMobileMenu"
							:to="$config.public.routes.Form.Popup"
							class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium">
							<span class="flex flex-row items-center space-x-1">
								<span>Forms</span>
							</span>
						</nuxt-link>
						<nuxt-link
							@click.stop="closeMobileMenu"
							:to="$config.public.routes.Automation.Home"
							class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium">
							<span class="flex flex-row items-center space-x-1">
								<span>Automations</span>
							</span>
						</nuxt-link>
					</div>

					<div
						class="px-2 pt-2 pb-3 flex flex-col space-y-1 border-b border-blue-800">
						<a
							v-if="
								store.checkVisibilitity({ entity: AppEntities.Contact })
							"
							@click.stop="contactSearchClickedMobile"
							href="#"
							class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium">
							<span class="flex flex-row items-center space-x-2">
								<!-- Heroicon name: outline/bell -->
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-5 w-5"
									viewBox="0 0 20 20"
									fill="currentColor">
									<path
										fill-rule="evenodd"
										d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
										clip-rule="evenodd" />
								</svg>
								<span>Search</span>
							</span>
						</a>
						<transition
							enter-active-class="transition ease-out duration-100"
							enter-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95">
							<div
								v-if="searchDropDownOpenMobile"
								v-click-outside="closeContactSearchMobile"
								class="z-20 origin-top-right absolute left-[150px] mt-2 w-72 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
								tabindex="-1"
								role="listbox"
								aria-labelledby="listbox-label"
								ref="optionsRef">
								<div class="p-2.5 flex items-center">
									<input
										autocomplete="off"
										data-lpignore="true"
										v-model="searchValue"
										type="text"
										name="firstName"
										id="firstName"
										class="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
										placeholder="Search contact by email ..." />

									<svg
										@click.stop="searchContact"
										class="ml-2 w-8 h-8 cursor-pointer"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<g
											id="SVGRepo_bgCarrier"
											stroke-width="0"></g>
										<g
											id="SVGRepo_tracerCarrier"
											stroke-linecap="round"
											stroke-linejoin="round"></g>
										<g id="SVGRepo_iconCarrier">
											<path
												d="M11.5003 12H5.41872M5.24634 12.7972L4.24158 15.7986C3.69128 17.4424 3.41613 18.2643 3.61359 18.7704C3.78506 19.21 4.15335 19.5432 4.6078 19.6701C5.13111 19.8161 5.92151 19.4604 7.50231 18.7491L17.6367 14.1886C19.1797 13.4942 19.9512 13.1471 20.1896 12.6648C20.3968 12.2458 20.3968 11.7541 20.1896 11.3351C19.9512 10.8529 19.1797 10.5057 17.6367 9.81135L7.48483 5.24303C5.90879 4.53382 5.12078 4.17921 4.59799 4.32468C4.14397 4.45101 3.77572 4.78336 3.60365 5.22209C3.40551 5.72728 3.67772 6.54741 4.22215 8.18767L5.24829 11.2793C5.34179 11.561 5.38855 11.7019 5.407 11.8459C5.42338 11.9738 5.42321 12.1032 5.40651 12.231C5.38768 12.375 5.34057 12.5157 5.24634 12.7972Z"
												stroke="#000000"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"></path>
										</g>
									</svg>
								</div>
								<ul class="mt-2">
									<li
										v-if="queryInProgress"
										class="font-semibold px-3 pb-1 text-md">
										Loading Contacts ...
									</li>
									<li
										v-else-if="
											!queryInProgress &&
											filteredContact?.length > 0 &&
											filteredContact?.[0].id > 0
										"
										class="cursor-pointer select-none px-2 text-sm"
										role="option">
										<span
											@click.stop="
												gotoContact(filteredContact?.[0].id)
											"
											class="font-semibold block truncate shadow-sm w-full pl-4 mx-2 py-2 hover:shadow-md rounded-md">
											{{ filteredContact?.[0].email }}
										</span>
									</li>
									<li
										v-else-if="
											!queryInProgress &&
											filteredContact?.[0].id === 0
										"
										class="font-semibold px-3 pb-1 text-md">
										No contacts found
									</li>
								</ul>
							</div>
						</transition>
						<!-- <a
              href="#"
              class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium"
            >
              <span class="flex flex-row items-center space-x-2">
                Heroicon name: outline/bell
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
                <span>Notification</span>
              </span>
            </a> -->
						<!-- 
            <a
              href="#"
              class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium"
            >
              <span class="flex flex-row items-center space-x-2">
                <svg
                  class="h-4 w-4"
                  viewBox="0 0 17 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.129883 4H4.12988V0H0.129883V4ZM6.12988 16H10.1299V12H6.12988V16ZM0.129883 16H4.12988V12H0.129883V16ZM0.129883 10H4.12988V6H0.129883V10ZM6.12988 10H10.1299V6H6.12988V10ZM12.1299 0V4H16.1299V0H12.1299ZM6.12988 4H10.1299V0H6.12988V4ZM12.1299 10H16.1299V6H12.1299V10ZM12.1299 16H16.1299V12H12.1299V16Z"
                    fill="white"
                  />
                </svg>
                <span>SendX Apps</span>
              </span>
            </a> -->
					</div>

					<div
						v-click-outside="closeDropDownMobile"
						class="relative px-2 pt-2 pb-3 flex flex-col space-y-1 border-b border-blue-800">
						<a
							href="#"
							@click.stop="handleProfileClick"
							class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium">
							<span class="flex flex-row items-center space-x-2">
								<img
									class="h-8 w-8 rounded-full"
									:src="
										store.$state.memberAvatarUrl != ''
											? store.$state.memberAvatarUrl
											: tempAvatarUrl
									"
									alt="" />
								<div class="text-white text-sm leading-5 font-normal">
									{{ currentTeam?.name }}
								</div>
							</span>
						</a>
						<transition
							enter-active-class="transition ease-out duration-100"
							enter-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95">
							<div
								v-if="profileDropDownOpenMobile"
								class="z-20 absolute bottom-20 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
								role="menu"
								aria-orientation="vertical"
								aria-labelledby="user-menu-button"
								tabindex="-1">
								<div class="flex flex-col divide-y divide-y-gray-50">
									<div
										class="flex px-4 py-2 flex-row space-x-4 items-center">
										<div>
											<span class="inline-block relative">
												<img
													class="h-10 w-10 rounded-full"
													:src="
														store.$state.memberAvatarUrl != ''
															? store.$state.memberAvatarUrl
															: tempAvatarUrl
													"
													alt="" />
												<span
													class="absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white bg-green-400"></span>
											</span>
										</div>
										<div class="flex flex-col justify-between">
											<div
												class="text-gray-800 text-sm leading-5 font-medium">
												{{ currentTeam?.name }}
											</div>
											<div class="text-gray-400 text-sm">
												{{
													currentTeam?.companyName !== ""
														? currentTeam?.companyName
														: "-"
												}}
											</div>
										</div>
									</div>

									<div class="flex flex-col">
										<nuxt-link
											:to="$config.public.routes.Profile"
											class="text-sm px-4 py-3 text-gray-700 hover:bg-gray-100"
											role="menuitem"
											tabindex="-1"
											id="user-menu-item-0">
											<div
												class="flex flex-row items-center space-x-4">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="h-5 w-5 text-gray-400"
													viewBox="0 0 20 20"
													fill="currentColor">
													<path
														fill-rule="evenodd"
														d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
														clip-rule="evenodd" />
												</svg>
												<span class="text-sm leading-5 font-normal"
													>My Profile</span
												>
											</div>
										</nuxt-link>

										<nuxt-link
											:to="$config.public.routes.Setting.Home"
											class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
											role="menuitem"
											tabindex="-1">
											<div
												class="flex flex-row items-center space-x-4">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="h-5 w-5 text-gray-400"
													viewBox="0 0 20 20"
													fill="currentColor">
													<path
														fill-rule="evenodd"
														d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
														clip-rule="evenodd" />
												</svg>
												<span class="text-sm leading-5 font-normal"
													>Settings</span
												>
											</div>
										</nuxt-link>
									</div>

									<div class="flex flex-col">
										<a
											target="_blank"
											rel="noreferrer"
											href="https://sendworks.com/support/sendx"
											class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
											role="menuitem"
											tabindex="-1">
											<div
												class="flex flex-row items-center space-x-4">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="h-5 w-5 text-gray-400"
													viewBox="0 0 20 20"
													fill="currentColor">
													<path
														fill-rule="evenodd"
														d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
														clip-rule="evenodd" />
												</svg>
												<span class="text-sm leading-5 font-normal"
													>Knowledge Base</span
												>
											</div>
										</a>

										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.sendworks.com/support/kb-tickets/new"
											class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
											role="menuitem"
											tabindex="-1">
											<div
												class="flex flex-row items-center space-x-4">
												<svg
													class="w-5 h-5"
													viewBox="0 0 1024.00 1024.00"
													xmlns="http://www.w3.org/2000/svg"
													fill="#9ca3af"
													stroke="#9ca3af"
													stroke-width="0.01024">
													<g
														id="SVGRepo_bgCarrier"
														stroke-width="0"></g>
													<g
														id="SVGRepo_tracerCarrier"
														stroke-linecap="round"
														stroke-linejoin="round"></g>
													<g id="SVGRepo_iconCarrier">
														<path
															fill="#9ca3af"
															d="M640 832H64V640a128 128 0 1 0 0-256V192h576v160h64V192h256v192a128 128 0 1 0 0 256v192H704V672h-64v160zm0-416v192h64V416h-64z"></path>
													</g>
												</svg>

												<span class="text-sm leading-5 font-normal"
													>Create Ticket</span
												>
											</div>
										</a>

										<div
											@click="openBeamerIdeas"
											class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100">
											<div
												class="flex flex-row items-center space-x-4">
												<svg
													version="1.1"
													id="Layer_1"
													xmlns="http://www.w3.org/2000/svg"
													xmlns:xlink="http://www.w3.org/1999/xlink"
													class="w-5 h-5 text-gray-400"
													viewBox="0 0 40 40"
													enable-background="new 0 0 40 40"
													xml:space="preserve"
													fill="#99a3af"
													stroke="#99a3af">
													<g
														id="SVGRepo_bgCarrier"
														stroke-width="0"></g>
													<g
														id="SVGRepo_tracerCarrier"
														stroke-linecap="round"
														stroke-linejoin="round"></g>
													<g id="SVGRepo_iconCarrier">
														<g display="none">
															<path
																display="inline"
																d="M20.01,36.5c-0.277,0-0.537-0.137-0.693-0.368L9.468,21.529c-1.171-1.861-1.79-3.993-1.79-6.162 C7.678,8.824,13.206,3.5,20,3.5c6.795,0,12.322,5.324,12.322,11.867c0,2.164-0.612,4.284-1.77,6.129l-9.851,14.636 C20.547,36.363,20.287,36.5,20.01,36.5L20.01,36.5z M20,5.17c-5.873,0-10.652,4.574-10.652,10.197c0,1.854,0.525,3.669,1.52,5.251 l9.14,13.55l9.146-13.581c0.981-1.566,1.499-3.371,1.499-5.22C30.652,9.744,25.873,5.17,20,5.17L20,5.17z"></path>
															<path
																display="inline"
																d="M20,20.857c-3.159,0-5.728-2.482-5.728-5.535c0-3.051,2.569-5.534,5.728-5.534s5.729,2.483,5.729,5.534 C25.729,18.375,23.158,20.857,20,20.857L20,20.857z M20,11.458c-2.237,0-4.057,1.734-4.057,3.864c0,2.13,1.82,3.865,4.057,3.865 s4.058-1.734,4.058-3.865C24.058,13.192,22.236,11.458,20,11.458L20,11.458z"></path>
														</g>
														<path
															display="none"
															d="M36.705,34.289L26.059,23.615c3.918-4.69,3.688-11.708-0.707-16.114C23.1,5.243,20.104,4,16.919,4 S10.74,5.243,8.488,7.501c-4.647,4.66-4.647,12.241,0,16.899c2.253,2.257,5.248,3.5,8.431,3.5c2.866,0,5.573-1.015,7.728-2.86 l10.639,10.665C35.479,35.902,35.738,36,35.994,36s0.514-0.098,0.709-0.293C37.096,35.316,37.097,34.68,36.705,34.289z M9.766,23.126c-3.945-3.958-3.945-10.395,0-14.351c1.912-1.914,4.452-2.97,7.153-2.97s5.243,1.056,7.153,2.97 c3.946,3.956,3.946,10.394,0,14.351c-1.91,1.914-4.452,2.969-7.153,2.969S11.678,25.04,9.766,23.126z"></path>
														<path
															display="none"
															d="M25.38,34.848c-0.066,0-0.136-0.009-0.206-0.024l-10.498-2.561l-10.61,2.561 c-0.069,0.016-0.139,0.024-0.205,0.024c-0.191,0-0.38-0.064-0.532-0.184C3.12,34.5,3,34.252,3,33.986V8.635 c0-0.397,0.27-0.741,0.657-0.836l10.76-2.623l0.407,0.003l10.504,2.558l10.607-2.561c0.065-0.016,0.135-0.023,0.203-0.023 c0.195,0,0.38,0.063,0.533,0.183C36.881,5.499,37,5.746,37,6.012v25.352c0,0.397-0.27,0.741-0.656,0.837l-10.759,2.622 C25.516,34.839,25.446,34.848,25.38,34.848L25.38,34.848z M15.481,30.688l9.039,2.203V9.311l-9.039-2.203V30.688z M26.24,9.311 v23.58l9.039-2.202V7.108L26.24,9.311z M4.721,9.311v23.58l9.039-2.202V7.108L4.721,9.311z"></path>
														<g display="none">
															<path
																display="inline"
																d="M9.708,35C7.112,35,5,32.893,5,30.303c0-2.592,2.112-4.699,4.708-4.699c2.595,0,4.707,2.107,4.707,4.699 C14.415,32.893,12.303,35,9.708,35L9.708,35z M9.708,27.445c-1.578,0-2.863,1.281-2.863,2.857c0,1.574,1.285,2.855,2.863,2.855 c1.578,0,2.861-1.281,2.861-2.855C12.568,28.727,11.285,27.445,9.708,27.445L9.708,27.445z"></path>
															<path
																display="inline"
																d="M24.574,35c-0.621,0-1.125-0.505-1.125-1.126c0-9.552-7.771-17.324-17.323-17.324 C5.505,16.55,5,16.045,5,15.425s0.505-1.126,1.126-1.126c10.792,0,19.573,8.781,19.573,19.575C25.699,34.495,25.193,35,24.574,35 L24.574,35z"></path>
															<path
																display="inline"
																d="M33.916,35c-0.597,0-1.082-0.486-1.082-1.084c0-14.75-12-26.751-26.751-26.751 C5.486,7.165,5,6.68,5,6.083C5,5.486,5.486,5,6.083,5C22.027,5,35,17.972,35,33.916C35,34.514,34.514,35,33.916,35L33.916,35z"></path>
														</g>
														<g display="none">
															<path
																display="inline"
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M20,29.616c-6.911,0-13.412-3.681-17-9.615 c3.588-5.935,10.089-9.617,17-9.617c6.91,0,13.412,3.682,17,9.617C33.412,25.936,26.91,29.616,20,29.616L20,29.616z M19.998,12.254 c-5.817,0-11.309,2.848-14.687,7.618l-0.088,0.125l0.088,0.124c3.378,4.77,8.87,7.618,14.687,7.618 c5.82,0,11.311-2.849,14.687-7.618l0.089-0.124l-0.089-0.125C31.309,15.103,25.818,12.254,19.998,12.254L19.998,12.254z"></path>
															<path
																display="inline"
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M19.987,13.521c-3.574,0-6.478,2.9-6.478,6.479 c0,3.579,2.904,6.478,6.478,6.478c3.581,0,6.478-2.898,6.478-6.478C26.465,16.42,23.568,13.521,19.987,13.521z M19.987,24.857 c-2.68,0-4.858-2.175-4.858-4.858s2.178-4.859,4.858-4.859c2.686,0,4.858,2.175,4.858,4.859S22.673,24.857,19.987,24.857z"></path>
														</g>
														<path
															display="none"
															d="M7.336,35.5c-0.399,0-0.725-0.325-0.725-0.726v-7.599c0-0.4,0.326-0.725,0.725-0.725h3.181v-6.336 c0-0.342,0.276-0.619,0.619-0.619h8.247v-5.949h-3.181c-0.398,0-0.725-0.325-0.725-0.726V5.223c0-0.399,0.326-0.723,0.725-0.723 h7.598c0.398,0,0.725,0.324,0.725,0.723v7.599c0,0.401-0.326,0.726-0.725,0.726H20.62v5.949h8.245c0.341,0,0.618,0.277,0.618,0.619 v6.336h3.182c0.397,0,0.724,0.324,0.724,0.725v7.599c0,0.399-0.326,0.725-0.724,0.725h-7.599c-0.4,0-0.726-0.325-0.726-0.725v-7.599 c0-0.4,0.325-0.725,0.726-0.725h3.179v-5.717H11.754v5.717h3.18c0.399,0,0.725,0.324,0.725,0.725v7.599 c0,0.4-0.325,0.726-0.725,0.726H7.336z M8.061,34.051h6.149V27.9H8.061V34.051z M25.79,34.051h6.149V27.9H25.79V34.051z M16.927,12.099h6.146V5.949h-6.146V12.099z"></path>
														<g display="none">
															<path
																display="inline"
																d="M11.018,35.01c-0.402,0-0.736-0.314-0.759-0.717L8.879,9.946H5.75c-0.419,0-0.76-0.342-0.76-0.76 c0-0.419,0.341-0.761,0.76-0.761h3.59L9.495,8.45L9.6,8.426h5.171V5.75c0-0.419,0.34-0.76,0.76-0.76h8.939 c0.419,0,0.76,0.341,0.76,0.76v2.676h5.174l0.175,0.028l0.118-0.028h3.554c0.419,0,0.76,0.342,0.76,0.761 c0,0.418-0.341,0.76-0.76,0.76h-3.128l-1.36,24.346c-0.023,0.402-0.356,0.718-0.759,0.718H11.018z M11.735,33.49h16.549L29.6,9.946 H10.4L11.735,33.49z M16.29,8.426h7.42V6.51h-7.42V8.426z"></path>
															<path
																display="inline"
																d="M14.302,12.201c-0.249,0.012-0.435,0.102-0.57,0.252c-0.135,0.152-0.203,0.348-0.192,0.551l0.998,17.608 c0.024,0.401,0.357,0.716,0.759,0.716v0.19l0.045-0.19c0.417-0.024,0.737-0.385,0.715-0.804l-0.999-17.606 C15.034,12.516,14.702,12.201,14.302,12.201L14.302,12.201z"></path>
															<path
																display="inline"
																d="M25.709,12.202c-0.445,0-0.776,0.314-0.8,0.716l-1,17.606c-0.022,0.419,0.298,0.779,0.717,0.804 l0.044,0.19v-0.19c0.402,0,0.735-0.314,0.757-0.716l1.001-17.608c0.011-0.203-0.058-0.398-0.192-0.551 c-0.136-0.15-0.321-0.24-0.523-0.251C25.711,12.202,25.71,12.202,25.709,12.202L25.709,12.202z"></path>
															<path
																display="inline"
																d="M20,12.201c-0.419,0-0.76,0.341-0.76,0.76v17.607c0,0.419,0.341,0.76,0.76,0.76s0.76-0.341,0.76-0.76 V12.961C20.76,12.542,20.419,12.201,20,12.201L20,12.201z"></path>
														</g>
														<g display="none">
															<path
																display="inline"
																d="M8.236,15.765C5.901,15.765,4,17.665,4,20c0,2.336,1.901,4.234,4.236,4.234 c2.336,0,4.235-1.898,4.235-4.234C12.472,17.665,10.572,15.765,8.236,15.765z M8.236,22.824c-1.557,0-2.823-1.268-2.823-2.824 c0-1.557,1.267-2.824,2.823-2.824c1.558,0,2.822,1.267,2.822,2.824C11.059,21.557,9.794,22.824,8.236,22.824z"></path>
															<path
																display="inline"
																d="M20,15.765c-2.334,0-4.236,1.899-4.236,4.235c0,2.336,1.902,4.234,4.236,4.234 c2.337,0,4.236-1.898,4.236-4.234C24.236,17.665,22.337,15.765,20,15.765z M20,22.824c-1.557,0-2.822-1.268-2.822-2.824 c0-1.557,1.266-2.824,2.822-2.824c1.559,0,2.822,1.267,2.822,2.824C22.822,21.557,21.559,22.824,20,22.824z"></path>
															<path
																display="inline"
																d="M31.764,15.765c-2.334,0-4.235,1.899-4.235,4.235c0,2.336,1.901,4.234,4.235,4.234 C34.101,24.234,36,22.336,36,20C36,17.665,34.101,15.765,31.764,15.765z M31.764,22.824c-1.556,0-2.822-1.268-2.822-2.824 c0-1.557,1.267-2.824,2.822-2.824c1.559,0,2.823,1.267,2.823,2.824C34.587,21.557,33.322,22.824,31.764,22.824z"></path>
														</g>
														<path
															display="none"
															d="M33.125,19.125H8.98L20.841,7.5c0.345-0.338,0.351-0.892,0.013-1.237s-0.892-0.35-1.237-0.012L6.262,19.339 C6.095,19.503,6,19.729,6,19.965c0,0.005,0,0.011,0,0.017C6,19.989,6,19.994,6,20c0,0.319,0.171,0.598,0.427,0.751L19.69,33.75 c0.17,0.167,0.392,0.25,0.613,0.25c0.227,0,0.453-0.088,0.625-0.262c0.338-0.346,0.332-0.9-0.013-1.238L9.055,20.875h24.07 C33.607,20.875,34,20.483,34,20S33.607,19.125,33.125,19.125z"></path>
														<path
															display="none"
															d="M34,20c0-0.32-0.172-0.599-0.428-0.751L20.31,6.25c-0.345-0.338-0.899-0.333-1.237,0.012 S18.738,7.161,19.084,7.5l11.861,11.625H6.875C6.393,19.125,6,19.517,6,20s0.393,0.875,0.875,0.875H31.02L19.158,32.5 c-0.344,0.339-0.35,0.893-0.012,1.238C19.318,33.912,19.545,34,19.771,34c0.221,0,0.442-0.083,0.612-0.25l13.353-13.089 C33.904,20.497,34,20.271,34,20.035c0-0.005-0.001-0.011-0.001-0.017C33.999,20.011,34,20.006,34,20z"></path>
														<path
															display="none"
															d="M33.75,19.616L20.66,6.262C20.496,6.095,20.271,6,20.037,6c-0.007,0-0.014,0.001-0.02,0.001S20.006,6,20,6 c-0.32,0-0.598,0.172-0.751,0.428L6.25,19.69c-0.338,0.345-0.332,0.899,0.013,1.237c0.171,0.167,0.391,0.25,0.612,0.25 c0.227-0.001,0.454-0.088,0.625-0.263l11.625-11.86v24.07C19.125,33.607,19.518,34,20,34c0.483,0,0.875-0.393,0.875-0.875V8.981 L32.5,20.841c0.338,0.345,0.893,0.35,1.237,0.013C34.082,20.515,34.088,19.96,33.75,19.616z"></path>
														<path
															display="none"
															d="M33.737,19.071c-0.345-0.337-0.899-0.332-1.237,0.014l-11.625,11.86V6.875C20.875,6.392,20.483,6,20,6 c-0.482,0-0.875,0.392-0.875,0.875V31.02L7.5,19.159c-0.338-0.345-0.892-0.351-1.237-0.013c-0.345,0.338-0.351,0.892-0.013,1.237 l13.09,13.354C19.504,33.905,19.729,34,19.965,34c0.005,0,0.012-0.001,0.018-0.001C19.989,33.999,19.994,34,20,34 c0.32,0,0.599-0.172,0.752-0.428L33.75,20.31C34.088,19.965,34.082,19.41,33.737,19.071z"></path>
														<path
															display="none"
															d="M26.714,34c-0.222,0-0.442-0.083-0.612-0.251l-13.429-13.16c-0.167-0.164-0.262-0.39-0.262-0.624 c0-0.236,0.095-0.462,0.262-0.626L26.027,6.251c0.344-0.339,0.898-0.334,1.237,0.011c0.338,0.346,0.331,0.899-0.013,1.237 L14.536,19.965l12.79,12.536c0.345,0.337,0.351,0.892,0.013,1.237C27.168,33.912,26.939,34,26.714,34z"></path>
														<path
															display="none"
															d="M13.36,34c-0.225,0-0.453-0.088-0.624-0.262c-0.339-0.346-0.333-0.899,0.01-1.237l12.717-12.466 L12.673,7.499c-0.345-0.339-0.35-0.893-0.013-1.237c0.34-0.344,0.894-0.35,1.238-0.012l13.429,13.161 c0.167,0.164,0.262,0.39,0.262,0.624c0,0.236-0.094,0.461-0.262,0.625L13.974,33.75C13.803,33.917,13.581,34,13.36,34z"></path>
														<path
															display="none"
															d="M5.938,28.131c-0.236,0-0.474-0.089-0.657-0.269c-0.368-0.362-0.375-0.956-0.012-1.325L19.37,12.149 c0.176-0.179,0.417-0.28,0.669-0.28s0.493,0.101,0.669,0.28l14.023,14.308c0.363,0.369,0.356,0.963-0.012,1.326 c-0.37,0.361-0.965,0.356-1.326-0.014L20.039,14.146L6.608,27.85C6.423,28.037,6.182,28.13,5.938,28.131z"></path>
														<path
															display="none"
															d="M19.962,28.131c-0.253,0-0.493-0.102-0.67-0.281L5.27,13.542c-0.364-0.37-0.357-0.963,0.011-1.326 c0.372-0.362,0.965-0.356,1.326,0.013l13.354,13.625l13.433-13.704c0.361-0.37,0.954-0.376,1.326-0.015 c0.368,0.363,0.375,0.957,0.013,1.327L20.632,27.85C20.454,28.029,20.214,28.131,19.962,28.131z"></path>
														<g display="none">
															<path
																display="inline"
																d="M6.72,34C6.323,34,6,33.678,6,33.281v-5.811c0-0.396,0.323-0.721,0.72-0.721h5.81 c0.397,0,0.72,0.324,0.72,0.721v5.811c0,0.396-0.323,0.719-0.72,0.719H6.72z M7.439,32.561h4.371v-4.371H7.439V32.561z"></path>
															<path
																display="inline"
																d="M17.095,34c-0.396,0-0.72-0.322-0.72-0.719v-5.811c0-0.396,0.323-0.721,0.72-0.721h5.811 c0.396,0,0.72,0.324,0.72,0.721v5.811c0,0.396-0.323,0.719-0.72,0.719H17.095z M17.815,32.561h4.371v-4.371h-4.371V32.561z"></path>
															<path
																display="inline"
																d="M27.471,34c-0.397,0-0.721-0.322-0.721-0.719v-5.811c0-0.396,0.323-0.721,0.721-0.721h5.81 c0.396,0,0.72,0.324,0.72,0.721v5.811C34,33.678,33.677,34,33.28,34H27.471z M28.189,32.561h4.371v-4.371h-4.371V32.561z"></path>
															<path
																display="inline"
																d="M6.72,23.625c-0.396,0-0.72-0.322-0.72-0.719v-5.811c0-0.397,0.323-0.72,0.72-0.72h5.81 c0.397,0,0.72,0.323,0.72,0.72v5.811c0,0.396-0.323,0.719-0.72,0.719H6.72z M7.439,22.186h4.371v-4.371H7.439V22.186z"></path>
															<path
																display="inline"
																d="M17.095,23.625c-0.396,0-0.72-0.322-0.72-0.719v-5.811c0-0.397,0.323-0.72,0.72-0.72h5.811 c0.396,0,0.72,0.323,0.72,0.72v5.811c0,0.396-0.323,0.719-0.72,0.719H17.095z M17.815,22.186h4.371v-4.371h-4.371V22.186z"></path>
															<path
																display="inline"
																d="M27.471,23.625c-0.397,0-0.721-0.322-0.721-0.719v-5.811c0-0.397,0.323-0.72,0.721-0.72h5.81 c0.396,0,0.72,0.323,0.72,0.72v5.811c0,0.396-0.323,0.719-0.72,0.719H27.471z M28.189,22.186h4.371v-4.371h-4.371V22.186z"></path>
															<path
																display="inline"
																d="M6.72,13.25C6.323,13.25,6,12.927,6,12.531v-5.81C6,6.324,6.323,6,6.72,6h5.81 c0.397,0,0.72,0.323,0.72,0.72v5.81c0,0.396-0.323,0.719-0.72,0.719H6.72z M7.439,11.811h4.371V7.439H7.439V11.811z"></path>
															<path
																display="inline"
																d="M17.095,13.25c-0.396,0-0.72-0.322-0.72-0.719v-5.81c0-0.397,0.323-0.72,0.72-0.72h5.811 c0.396,0,0.72,0.323,0.72,0.72v5.81c0,0.396-0.323,0.719-0.72,0.719H17.095z M17.815,11.811h4.371V7.439h-4.371V11.811z"></path>
															<path
																display="inline"
																d="M27.471,13.25c-0.397,0-0.721-0.322-0.721-0.719v-5.81c0-0.397,0.323-0.72,0.721-0.72h5.81 C33.677,6,34,6.324,34,6.721v5.81c0,0.396-0.323,0.719-0.72,0.719H27.471z M28.189,11.811h4.371V7.439h-4.371V11.811z"></path>
														</g>
														<g display="none">
															<path
																display="inline"
																d="M16.225,8.013h16.949C33.63,8.013,34,7.642,34,7.185c0-0.455-0.37-0.826-0.826-0.826H16.225 c-0.457,0-0.827,0.371-0.827,0.826C15.398,7.642,15.768,8.013,16.225,8.013z"></path>
															<path
																display="inline"
																d="M33.174,11.423H16.225c-0.457,0-0.827,0.369-0.827,0.826s0.37,0.826,0.827,0.826h16.949 c0.456,0,0.826-0.369,0.826-0.826S33.63,11.423,33.174,11.423z"></path>
															<path
																display="inline"
																d="M33.174,16.692H16.225c-0.457,0-0.827,0.371-0.827,0.827s0.37,0.827,0.827,0.827h16.949 c0.456,0,0.826-0.371,0.826-0.827S33.63,16.692,33.174,16.692z"></path>
															<path
																display="inline"
																d="M33.174,21.653H16.225c-0.457,0-0.827,0.371-0.827,0.827s0.37,0.827,0.827,0.827h16.949 c0.456,0,0.826-0.371,0.826-0.827S33.63,21.653,33.174,21.653z"></path>
															<path
																display="inline"
																d="M33.174,27.027H16.225c-0.457,0-0.827,0.37-0.827,0.827c0,0.456,0.37,0.826,0.827,0.826h16.949 c0.456,0,0.826-0.37,0.826-0.826C34,27.397,33.63,27.027,33.174,27.027z"></path>
															<path
																display="inline"
																d="M33.174,31.987H16.225c-0.457,0-0.827,0.371-0.827,0.828c0,0.455,0.37,0.826,0.827,0.826h16.949 c0.456,0,0.826-0.371,0.826-0.826C34,32.358,33.63,31.987,33.174,31.987z"></path>
															<path
																display="inline"
																d="M6.717,33.53C6.322,33.53,6,33.21,6,32.815v-4.961c0-0.396,0.322-0.717,0.717-0.717h4.961 c0.395,0,0.717,0.321,0.717,0.717v4.961c0,0.395-0.322,0.715-0.717,0.715H6.717z M7.434,32.098h3.526V28.57H7.434V32.098z"></path>
															<path
																display="inline"
																d="M6.717,23.196C6.322,23.196,6,22.876,6,22.48V17.52c0-0.396,0.322-0.717,0.717-0.717h4.961 c0.395,0,0.717,0.321,0.717,0.717v4.961c0,0.396-0.322,0.716-0.717,0.716H6.717z M7.434,21.763h3.526v-3.527H7.434V21.763z"></path>
															<path
																display="inline"
																d="M6.717,12.862C6.322,12.862,6,12.541,6,12.146V7.185C6,6.79,6.322,6.468,6.717,6.468h4.961 c0.395,0,0.717,0.322,0.717,0.717v4.961c0,0.396-0.322,0.717-0.717,0.717H6.717z M7.434,11.429h3.526V7.901H7.434V11.429z"></path>
														</g>
														<g display="none">
															<path
																display="inline"
																d="M34.063,10.625H5.938C5.419,10.625,5,10.204,5,9.688C5,9.17,5.419,8.75,5.938,8.75h28.125 C34.58,8.75,35,9.17,35,9.688C35,10.204,34.58,10.625,34.063,10.625"></path>
															<path
																display="inline"
																d="M34.063,20.938H5.938C5.419,20.938,5,20.517,5,20c0-0.518,0.419-0.938,0.938-0.938h28.125 C34.58,19.063,35,19.482,35,20C35,20.517,34.58,20.938,34.063,20.938"></path>
															<path
																display="inline"
																d="M34.063,31.25H5.938C5.419,31.25,5,30.829,5,30.313c0-0.518,0.419-0.938,0.938-0.938h28.125 c0.518,0,0.938,0.42,0.938,0.938C35,30.829,34.58,31.25,34.063,31.25"></path>
															<path
																display="inline"
																d="M34.063,20.938H5.938C5.419,20.938,5,20.517,5,20c0-0.518,0.419-0.938,0.938-0.938h28.125 C34.58,19.063,35,19.482,35,20C35,20.517,34.58,20.938,34.063,20.938"></path>
														</g>
														<path
															display="none"
															d="M21.414,20L33.207,8.207c0.391-0.39,0.391-1.023,0-1.414c-0.391-0.391-1.023-0.391-1.414,0L20,18.586 L8.207,6.793c-0.39-0.391-1.023-0.391-1.414,0c-0.391,0.391-0.391,1.023,0,1.414L18.586,20L6.793,31.793 c-0.391,0.391-0.391,1.023,0,1.414C6.988,33.402,7.245,33.5,7.5,33.5c0.256,0,0.511-0.098,0.707-0.293L20,21.414l11.793,11.793 c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L21.414,20z"></path>
														<path
															display="none"
															d="M9.411,35C9,35,8.653,34.658,8.653,34.254V5.758C8.653,5.34,8.992,5,9.411,5h14.706 c0.198,0,0.394,0.084,0.546,0.236l6.449,6.461c0.15,0.13,0.235,0.324,0.235,0.535v22.021C31.347,34.658,31,35,30.589,35H9.411z M10.17,33.496h19.672V12.989h-5.726c-0.419,0-0.759-0.34-0.759-0.757V6.504H10.17V33.496z M24.875,11.485h3.9l-3.9-3.914V11.485z"></path>
														<g display="none">
															<path
																display="inline"
																d="M12.966,17.262c-0.483,0-0.878,0.395-0.878,0.878s0.395,0.877,0.878,0.877h13.518 c0.483,0,0.878-0.394,0.878-0.877s-0.395-0.878-0.878-0.878H12.966L12.966,17.262z"></path>
															<path
																display="inline"
																d="M9.411,35C9,35,8.652,34.658,8.652,34.254V5.758C8.652,5.34,8.992,5,9.411,5h14.704 c0.2,0,0.395,0.084,0.547,0.236l6.449,6.461c0.15,0.13,0.236,0.324,0.236,0.534v22.022c0,0.404-0.349,0.746-0.759,0.746H9.411z M10.17,33.496h19.672V12.989h-5.727c-0.417,0-0.758-0.34-0.758-0.757V6.504H10.17V33.496z M24.875,11.485h3.9l-3.9-3.915V11.485z"></path>
															<path
																display="inline"
																d="M12.966,21.164c-0.483,0-0.878,0.395-0.878,0.878s0.395,0.878,0.878,0.878h13.518 c0.483,0,0.878-0.395,0.878-0.878s-0.395-0.878-0.878-0.878H12.966L12.966,21.164z"></path>
															<path
																display="inline"
																d="M12.966,25.065c-0.483,0-0.878,0.395-0.878,0.878c0,0.484,0.395,0.877,0.878,0.877h13.518 c0.483,0,0.878-0.393,0.878-0.877c0-0.483-0.395-0.878-0.878-0.878H12.966L12.966,25.065z"></path>
														</g>
														<g display="none">
															<path
																display="inline"
																d="M14.766,16.227c0,0.45,0.367,0.816,0.817,0.816h12.578c0.449,0,0.817-0.367,0.817-0.816 c0-0.451-0.368-0.817-0.817-0.817H15.583C15.133,15.41,14.766,15.776,14.766,16.227z"></path>
															<path
																display="inline"
																d="M32.468,10.232l-6.002-6.013C26.324,4.078,26.144,4,25.959,4H12.274c-0.389,0-0.706,0.316-0.706,0.706 v26.516c0,0.377,0.323,0.694,0.706,0.694h14.757v2.685H8.727V9.484H11.5v-1.4H8.02c-0.389,0-0.706,0.317-0.706,0.707v26.515 C7.313,35.683,7.637,36,8.02,36h19.706c0.383,0,0.706-0.317,0.706-0.694v-3.39h3.549c0.383,0,0.706-0.317,0.706-0.694V10.729 C32.687,10.533,32.607,10.354,32.468,10.232z M26.664,6.393l3.63,3.642h-3.63V6.393z M31.286,30.516H12.981V5.399h12.271v5.33 c0,0.388,0.315,0.705,0.706,0.705h5.327V30.516z"></path>
															<path
																display="inline"
																d="M28.161,19.041H15.583c-0.45,0-0.817,0.367-0.817,0.817c0,0.45,0.367,0.816,0.817,0.816h12.578 c0.449,0,0.817-0.367,0.817-0.816C28.979,19.407,28.61,19.041,28.161,19.041z"></path>
															<path
																display="inline"
																d="M28.161,22.672H15.583c-0.45,0-0.817,0.365-0.817,0.816s0.367,0.816,0.817,0.816h12.578 c0.449,0,0.817-0.365,0.817-0.816S28.61,22.672,28.161,22.672z"></path>
															<path
																display="inline"
																d="M11.5,19.495h-0.172c-0.45,0-0.817,0.367-0.817,0.816c0,0.451,0.367,0.818,0.817,0.818H11.5V19.495z"></path>
															<path
																display="inline"
																d="M11.5,23.125h-0.172c-0.45,0-0.817,0.367-0.817,0.816c0,0.451,0.367,0.818,0.817,0.818H11.5V23.125z"></path>
															<path
																display="inline"
																d="M10.511,27.573c0,0.45,0.367,0.816,0.817,0.816H11.5v-1.634h-0.172 C10.878,26.756,10.511,27.123,10.511,27.573z"></path>
														</g>
														<path
															display="none"
															d="M35.823,14.582c-0.145-0.174-0.359-0.274-0.585-0.274h-2.286V8.381c0-0.42-0.341-0.762-0.762-0.762H7.81 c-0.421,0-0.762,0.341-0.762,0.762v3.279H4.762c-0.222,0-0.435,0.097-0.579,0.267c-0.145,0.169-0.208,0.394-0.173,0.614 l3.048,19.197c0.06,0.369,0.377,0.643,0.752,0.643H32.19c0.367,0,0.683-0.264,0.75-0.625l3.048-16.549 C36.028,14.985,35.969,14.755,35.823,14.582z M8.571,9.144h22.857v5.165H16.437l-3.621-2.512c-0.127-0.089-0.277-0.136-0.435-0.136 h-3.81V9.144z M31.558,30.856H8.46L5.655,13.184h6.487l3.621,2.511c0.127,0.089,0.28,0.137,0.435,0.137h18.125L31.558,30.856z"></path>
														<path
															display="none"
															d="M8.944,35c-1.054,0-2.044-0.41-2.789-1.156C5.41,33.1,5,32.109,5,31.056s0.41-2.044,1.155-2.788 l12.549-12.549l-0.896-3.995c-0.074-0.276,0.004-0.569,0.202-0.768l5.727-5.724C23.887,5.083,24.084,5,24.295,5 c0.069,0,0.138,0.009,0.203,0.027L28.8,6.18c0.271,0.072,0.485,0.285,0.558,0.556c0.072,0.272-0.006,0.564-0.203,0.762l-4.27,4.271 l0.609,2.497l2.623,0.883l4.383-4.301c0.15-0.15,0.348-0.232,0.56-0.232c0.069,0,0.138,0.009,0.204,0.026 c0.271,0.073,0.484,0.288,0.557,0.559l1.152,4.302c0.074,0.272-0.004,0.563-0.203,0.761l-5.727,5.726 c-0.149,0.15-0.347,0.231-0.558,0.231c-0.069,0-0.138-0.009-0.203-0.027l-3.673-0.983l-2.324,2.084L11.733,33.845 C10.988,34.59,9.997,35,8.944,35L8.944,35z M19.449,11.75l1.035,3.862c0.072,0.271-0.006,0.563-0.204,0.761L7.27,29.382 c-0.447,0.446-0.694,1.042-0.694,1.674c0,0.633,0.247,1.227,0.694,1.673c0.449,0.448,1.043,0.695,1.674,0.695 c0.632,0,1.227-0.247,1.674-0.695l13.009-13.005c0.146-0.147,0.35-0.231,0.559-0.231c0.069,0,0.138,0.009,0.203,0.026l3.672,0.983 l5.131-4.89l-0.387-2.106l-0.154-0.579l-3.672,3.673c-0.149,0.149-0.348,0.231-0.558,0.231c-0.069,0-0.138-0.009-0.203-0.026 l-3.521-0.943c-0.271-0.072-0.484-0.285-0.557-0.556l-0.943-3.521c-0.072-0.272,0.006-0.564,0.203-0.762l3.673-3.672l-2.544-0.68 L19.449,11.75z"></path>
														<path
															display="none"
															d="M35.667,18.241L20.476,7.193C20.338,7.091,20.173,7.038,20,7.038s-0.337,0.054-0.477,0.155l-6.715,4.884 v-1.85c0-0.53-0.371-0.961-0.828-0.961H8.334c-0.457,0-0.828,0.432-0.828,0.961v5.706l-3.173,2.308 c-0.361,0.263-0.441,0.771-0.178,1.132c0.151,0.208,0.397,0.332,0.655,0.332c0.172,0,0.336-0.053,0.477-0.154l2.321-1.688v14.29 c0,0.447,0.363,0.811,0.81,0.811h8.178c0.447,0,0.81-0.363,0.81-0.811v-9.715h5.191v9.715c0,0.447,0.362,0.811,0.809,0.811h8.178 c0.447,0,0.811-0.363,0.811-0.811v-14.29l2.322,1.688c0.137,0.101,0.301,0.154,0.475,0.154c0.259,0,0.504-0.124,0.656-0.332 C36.107,19.011,36.027,18.504,35.667,18.241z M9.162,11.188h1.991v2.092l-1.991,1.448V11.188z M30.772,31.342h-6.558v-9.714 c0-0.446-0.363-0.81-0.811-0.81h-6.809c-0.447,0-0.81,0.364-0.81,0.81v9.714H9.228V16.684L20,8.85l10.772,7.834V31.342z"></path>
														<g display="none">
															<path
																display="inline"
																d="M9.196,36c-0.43,0-0.766-0.338-0.766-0.767c0-0.431,0.336-0.768,0.766-0.768h2.006V10.234l-0.071-0.021 c-0.667-0.201-1.228-0.588-1.666-1.148c-0.436-0.555-0.656-1.201-0.656-1.92c0-0.88,0.308-1.63,0.915-2.229 C10.337,4.308,11.092,4,11.968,4c0.888,0,1.627,0.299,2.26,0.915c0.617,0.604,0.93,1.354,0.93,2.229 c0,0.708-0.226,1.354-0.672,1.92c-0.449,0.576-0.998,0.951-1.681,1.147l-0.072,0.021v0.956l10.374,2.568 c0.359,0.089,0.602,0.397,0.602,0.77v2.013l9.285,2.478c0.355,0.093,0.543,0.315,0.572,0.681c0.027,0.363-0.113,0.622-0.417,0.77 l-9.903,4.772c-0.1,0.066-0.202,0.098-0.32,0.098c-0.144,0-0.285-0.043-0.418-0.125c-0.237-0.148-0.348-0.357-0.348-0.656v-1.558 l-9.426,3.362v8.104h2.037c0.438,0,0.782,0.337,0.782,0.768c0,0.429-0.343,0.767-0.782,0.767H9.196z M12.734,24.729l9.426-3.359 v-6.242l-9.426-2.334V24.729z M23.709,23.33l6.888-3.335l-6.888-1.85V23.33z M11.964,5.534c-0.433,0-0.836,0.166-1.137,0.468 c-0.625,0.624-0.625,1.654-0.001,2.297c0.297,0.306,0.701,0.474,1.138,0.474c0.437,0,0.848-0.168,1.16-0.473 c0.321-0.312,0.497-0.726,0.494-1.165C13.615,6.702,13.439,6.3,13.123,6C12.808,5.7,12.396,5.534,11.964,5.534L11.964,5.534z"></path>
														</g>
														<path
															display="none"
															d="M33.334,12.095h-6.857v-1.143c0-1.996-1.623-3.619-3.619-3.619h-5.714c-1.995,0-3.619,1.623-3.619,3.619 v1.143H6.667C5.196,12.095,4,13.292,4,14.762v5.714V30c0,1.471,1.196,2.666,2.667,2.666h26.667C34.805,32.666,36,31.471,36,30 v-9.524v-5.714C36,13.292,34.805,12.095,33.334,12.095z M15.048,10.952c0-1.154,0.94-2.095,2.095-2.095h5.714 c1.155,0,2.095,0.941,2.095,2.095v1.143h-9.904V10.952z M5.524,14.762c0-0.631,0.513-1.143,1.143-1.143h26.667 c0.631,0,1.143,0.512,1.143,1.143v4.953H5.524V14.762z M24.762,21.238h3.81v2.158h-3.81V21.238z M11.43,21.238h3.81v2.158h-3.81 V21.238z M33.334,31.143H6.667c-0.63,0-1.143-0.514-1.143-1.143v-8.762h4.382v2.92c0,0.422,0.34,0.762,0.762,0.762h5.333 c0.421,0,0.762-0.34,0.762-0.762v-2.92h6.476v2.92c0,0.422,0.342,0.762,0.762,0.762h5.334c0.421,0,0.762-0.34,0.762-0.762v-2.92 h4.381V30C34.477,30.629,33.965,31.143,33.334,31.143z"></path>
														<path
															display="none"
															d="M25.033,14.427v-5.28c1.936-0.543,3.324-2.324,3.324-4.388c0-0.42-0.34-0.76-0.76-0.76H12.402 c-0.419,0-0.76,0.339-0.76,0.76c0,2.063,1.389,3.844,3.324,4.388v5.28c-1.935,0.543-3.324,2.324-3.324,4.389v1.899 c0,0.419,0.34,0.759,0.76,0.759h5.01c0.125,3.184,0.419,6.59,0.821,9.459c0.186,1.328,0.384,2.461,0.572,3.277 C19.009,35.09,19.218,36,20,36c0.781,0,0.991-0.91,1.195-1.789c0.188-0.816,0.386-1.949,0.572-3.277 c0.402-2.869,0.697-6.275,0.822-9.459h5.008c0.42,0,0.76-0.34,0.76-0.759v-1.899C28.357,16.751,26.969,14.97,25.033,14.427z M20,32.434c-0.412-2.4-0.888-6.395-1.068-10.959h2.137C20.891,26.039,20.414,30.033,20,32.434z M26.838,19.956h-4.982h-3.711 h-4.982v-1.14c0-1.531,1.144-2.826,2.659-3.015c0.38-0.047,0.665-0.371,0.665-0.754V8.527c0-0.383-0.285-0.706-0.665-0.753 c-1.256-0.156-2.258-1.073-2.563-2.254h13.482c-0.305,1.181-1.306,2.098-2.561,2.254c-0.38,0.047-0.667,0.371-0.667,0.753v6.521 c0,0.383,0.287,0.707,0.667,0.754c1.516,0.189,2.657,1.484,2.657,3.015V19.956z"></path>
														<g display="none">
															<path
																display="inline"
																d="M27.758,32.244L34,19.074V7.756H21.241V19.76h4.87l-4.87,12.484H27.758z M23.146,17.855V9.662h8.948 v8.983l-5.543,11.693h-2.522l4.868-12.482H23.146z"></path>
															<path
																display="inline"
																d="M12.517,32.244l6.242-13.17V7.756H6V19.76h4.871L6,32.244H12.517z M7.905,17.855V9.662h8.948v8.983 l-5.542,11.693H8.789l4.868-12.482H7.905z"></path>
														</g>
														<g display="none">
															<path
																display="inline"
																d="M23.83,28.146c-0.414,0-0.75,0.337-0.75,0.75v5.25H8.287V5.854H23.08v5.25c0,0.414,0.336,0.75,0.75,0.75 s0.75-0.336,0.75-0.75v-6c0-0.414-0.336-0.75-0.75-0.75H7.537c-0.414,0-0.75,0.336-0.75,0.75v29.793c0,0.415,0.336,0.75,0.75,0.75 H23.83c0.414,0,0.75-0.335,0.75-0.75v-6C24.58,28.483,24.244,28.146,23.83,28.146z"></path>
															<path
																display="inline"
																d="M34.168,24.248l-2.48-8.688c-0.08-0.277-0.311-0.484-0.594-0.533c-0.285-0.05-0.572,0.069-0.738,0.304 L29.307,16.8c-1.635-1.122-3.586-1.735-5.693-1.783c-0.02-0.001-0.037,0-0.057,0.001c-4.104,0.216-6.771,1.523-10.574,5.178 c-0.258,0.248-0.305,0.642-0.115,0.942c0.191,0.302,0.568,0.426,0.902,0.3c1.627-0.619,3.34-0.946,4.955-0.946 c1.795,0,4.295,0.403,6.336,2.252l-0.947,1.327c-0.162,0.229-0.186,0.529-0.057,0.779s0.387,0.407,0.668,0.407h8.738l0,0 c0.416,0,0.75-0.336,0.75-0.75C34.213,24.416,34.197,24.329,34.168,24.248z M26.182,23.757l0.475-0.665 c0.209-0.292,0.18-0.692-0.068-0.953c-1.961-2.059-4.68-3.147-7.863-3.147c-0.754,0-1.527,0.063-2.305,0.188 c2.352-1.809,4.373-2.511,7.188-2.663c2.041,0.052,3.906,0.717,5.391,1.925c0.162,0.131,0.369,0.189,0.574,0.161 c0.205-0.027,0.391-0.139,0.51-0.308l0.59-0.826l1.795,6.288H26.182z"></path>
														</g>
														<path
															display="none"
															d="M11.087,35.498c-0.655,0-1.272-0.256-1.737-0.719l-4.128-4.13C4.756,30.185,4.5,29.567,4.5,28.912 c0-0.656,0.256-1.274,0.722-1.738l8.354-8.355c0.466-0.464,1.083-0.719,1.738-0.719c0.656,0,1.274,0.255,1.736,0.719l1.483,1.484 l1.771-1.771l-1.485-1.482c-0.463-0.464-0.72-1.082-0.72-1.738s0.257-1.272,0.72-1.737l8.356-8.354 c0.463-0.465,1.08-0.72,1.737-0.72s1.274,0.254,1.737,0.72l4.13,4.128c0.464,0.463,0.72,1.08,0.72,1.737s-0.256,1.275-0.72,1.738 l-8.355,8.355c-0.464,0.465-1.081,0.72-1.738,0.72c-0.654,0-1.272-0.255-1.736-0.72l-1.483-1.484l-1.77,1.771l1.483,1.482 c0.466,0.464,0.72,1.081,0.72,1.736c0,0.656-0.254,1.273-0.72,1.738l-8.354,8.355C12.361,35.242,11.744,35.498,11.087,35.498 L11.087,35.498z M15.313,19.747c-0.125,0-0.365,0.03-0.571,0.236l-8.356,8.356c-0.206,0.206-0.237,0.448-0.237,0.573 s0.031,0.366,0.237,0.572l4.13,4.13c0.207,0.207,0.447,0.237,0.571,0.237c0.125,0,0.367-0.03,0.573-0.237l8.356-8.354 c0.207-0.207,0.236-0.448,0.236-0.574c0-0.124-0.029-0.364-0.236-0.57l-1.483-1.486l-2.482,2.484 c-0.155,0.156-0.362,0.242-0.581,0.242c-0.222,0-0.428-0.086-0.582-0.242c-0.321-0.322-0.321-0.844-0.002-1.163l2.484-2.483 l-1.483-1.483C15.679,19.777,15.438,19.747,15.313,19.747L15.313,19.747z M24.651,14.526c0.219,0,0.426,0.085,0.58,0.24 c0.156,0.156,0.244,0.363,0.244,0.583s-0.088,0.426-0.242,0.581l-2.604,2.602l1.485,1.483c0.206,0.206,0.446,0.236,0.571,0.236 c0.126,0,0.367-0.03,0.574-0.235l8.354-8.356c0.208-0.206,0.237-0.448,0.237-0.574c0-0.125-0.029-0.365-0.235-0.573l-4.129-4.128 c-0.208-0.208-0.449-0.238-0.574-0.238s-0.367,0.03-0.573,0.238l-8.354,8.354c-0.208,0.206-0.239,0.448-0.239,0.573 s0.031,0.366,0.236,0.573l1.483,1.483l2.601-2.602C24.225,14.612,24.431,14.526,24.651,14.526L24.651,14.526z"></path>
														<g display="none">
															<path
																display="inline"
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M13.786,29.113c-1.022,0-1.853-0.829-1.853-1.85l-0.245-1.479 l-0.287-1.924c-0.052-0.188-0.076-0.348-0.076-0.498L9.373,9.702L9.035,7.667H5.377C4.894,7.667,4.5,7.274,4.5,6.791 c0-0.482,0.394-0.875,0.877-0.875h3.899c0.056,0,0.111,0.014,0.169,0.03l0.025,0.006l0.075,0.007 C9.652,5.93,9.708,5.915,9.763,5.915c0.484,0,0.878,0.393,0.878,0.875l0.186,1.122l0.097-0.017 c0.099-0.017,0.199-0.033,0.304-0.033h22.421c1.021,0,1.852,0.831,1.852,1.853v11.699c0,1.021-0.83,1.851-1.852,1.851 c-0.092,0.001-9.072,0.071-14.472,0.638c-2.596,0.273-5.058,1.01-5.75,1.229l-0.079,0.025l0.329,2.203h18.997 c0.482,0,0.877,0.394,0.877,0.877s-0.395,0.876-0.877,0.876H13.786z M12.445,9.618c-0.592,0-1.073,0.48-1.073,1.071l1.626,11.387 c-0.001,0.578,0.481,1.059,1.074,1.059c0.054-0.015,1.638-0.682,4.483-1.018c4.797-0.565,14.026-0.605,14.12-0.605 c0.59,0,1.072-0.48,1.072-1.073v-9.75c0-0.59-0.482-1.071-1.072-1.071H12.445z"></path>
															<path
																display="inline"
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M15.948,30.188c-1.076,0-1.948,0.872-1.948,1.949 c0,1.076,0.872,1.948,1.948,1.948c1.079,0,1.951-0.872,1.951-1.948C17.899,31.06,17.027,30.188,15.948,30.188z"></path>
															<circle
																display="inline"
																fill-rule="evenodd"
																clip-rule="evenodd"
																cx="29.598"
																cy="32.136"
																r="1.949"></circle>
														</g>
														<path
															display="none"
															fill="#010101"
															d="M32,10.465c0-0.948-0.66-2.295-3.808-3.337C25.993,6.4,23.084,6,20,6 c-3.083,0-5.993,0.4-8.192,1.128C8.661,8.169,8,9.517,8,10.465c0,0.008,0,0.014,0.001,0.022C8.001,10.491,8,10.495,8,10.496v6.326 c0,0.007,0,0.014,0.001,0.023C8.001,16.848,8,16.851,8,16.853v6.325c0,0.008,0,0.016,0.001,0.023c0,0.003-0.001,0.007-0.001,0.01 v6.324c0,0.947,0.661,2.295,3.808,3.337C14.007,33.6,16.917,34,20,34c3.084,0,5.993-0.4,8.192-1.128 C31.34,31.83,32,30.482,32,29.535v-6.324c0-0.003,0-0.007,0-0.01c0-0.008,0-0.016,0-0.023v-6.325c0-0.002,0-0.005,0-0.008 c0-0.009,0-0.016,0-0.023v-6.326c0-0.001,0-0.005,0-0.009C32,10.479,32,10.473,32,10.465z M30.5,29.535 c0,1.215-4.09,2.977-10.5,2.977S9.5,30.75,9.5,29.535v-4.052c0.557,0.371,1.306,0.732,2.308,1.063 c2.199,0.729,5.109,1.129,8.192,1.129c3.084,0,5.993-0.4,8.192-1.129c1.001-0.331,1.751-0.692,2.308-1.063V29.535z M30.5,23.178 c0,1.214-4.09,2.977-10.5,2.977S9.5,24.392,9.5,23.178v-4.052c0.557,0.371,1.306,0.733,2.308,1.065 c2.199,0.728,5.109,1.127,8.192,1.127c3.084,0,5.993-0.4,8.192-1.127c1.001-0.332,1.751-0.694,2.308-1.065V23.178z M30.5,16.822 c0,1.213-4.09,2.975-10.5,2.975S9.5,18.035,9.5,16.822v-4.053c0.557,0.371,1.306,0.733,2.308,1.065 c2.199,0.728,5.109,1.128,8.192,1.128c3.084,0,5.993-0.4,8.192-1.128c1.001-0.332,1.751-0.694,2.308-1.065V16.822z M20,13.44 c-6.41,0-10.5-1.762-10.5-2.975c0-1.214,4.09-2.976,10.5-2.976s10.5,1.762,10.5,2.976C30.5,11.678,26.41,13.44,20,13.44z"></path>
														<path
															display="none"
															fill="#010101"
															d="M31,10V6.667C31,6.299,30.647,6,30.214,6H9.785C9.353,6,9,6.299,9,6.667V10 c0,0.369,0.353,0.667,0.785,0.667h1.179v0.996c0,3.418,2.296,6.533,5.893,8.052v0.569c-3.596,1.518-5.893,4.629-5.893,8.049v1.002 H9.785C9.353,29.334,9,29.633,9,29.999v3.335C9,33.702,9.353,34,9.785,34h20.429C30.647,34,31,33.702,31,33.334v-3.335 c0-0.366-0.353-0.665-0.786-0.665h-1.179v-1.002c0-3.42-2.297-6.533-5.896-8.051v-0.565c3.599-1.518,5.896-4.632,5.896-8.054v-0.996 h1.179C30.647,10.667,31,10.369,31,10z M28.572,30.666h0.855v2H10.571v-2h1.179h16.5H28.572z M27.18,13.566 c-0.148,0.574-0.566,1.333-0.566,1.333c-0.914,1.655-2.516,3.018-4.575,3.783c-0.286,0.106-0.47,0.346-0.47,0.611v1.413 c0,0.265,0.184,0.504,0.47,0.611c3.296,1.226,5.427,3.979,5.427,7.016v1.003h-0.686h-2.015h-9.532h-1.998h-0.7v-1.002 c0-3.037,2.13-5.789,5.423-7.016c0.286-0.105,0.47-0.344,0.47-0.609V19.29c0-0.264-0.184-0.503-0.47-0.61 c-2.057-0.766-3.658-2.127-4.572-3.782c-0.344-0.56-0.565-1.333-0.565-1.333c-0.187-0.613-0.286-1.252-0.286-1.903v-0.985h14.93 v0.985C27.465,12.313,27.365,12.953,27.18,13.566z M29.428,9.333H28.25c-0.046,0-0.09,0.004-0.134,0.01H11.883 c-0.043-0.006-0.087-0.01-0.133-0.01h-1.179v-2h18.856V9.333z"></path>
														<path
															display="none"
															fill="#010101"
															d="M29.112,30.927H9.876C6.085,30.927,3,27.842,3,24.051c0-3.617,2.806-6.591,6.356-6.858 c0.77-2.392,3.021-4.066,5.583-4.066c0.369,0,0.744,0.041,1.133,0.125c1.362-2.559,4.023-4.177,6.963-4.177 c3.729,0,6.934,2.659,7.688,6.276C34.342,16.105,37,19.311,37,23.039C37,27.389,33.462,30.927,29.112,30.927z M9.833,18.791 c-2.879,0.024-5.215,2.375-5.215,5.26c0,2.898,2.359,5.258,5.258,5.258h19.236c3.457,0,6.271-2.813,6.271-6.27 c0-3.122-2.35-5.78-5.466-6.185c-0.364-0.048-0.651-0.334-0.698-0.699c-0.404-3.115-3.063-5.463-6.184-5.463 c-2.527,0-4.794,1.504-5.779,3.831c-0.163,0.387-0.596,0.584-0.996,0.453c-0.48-0.156-0.912-0.232-1.32-0.232 c-2.018,0-3.769,1.434-4.164,3.409c-0.076,0.377-0.408,0.65-0.793,0.65C9.921,18.803,9.869,18.796,9.833,18.791z"></path>
														<g display="none">
															<path
																display="inline"
																fill="#010101"
																d="M29.364,15.71c-0.431,0-0.78,0.35-0.78,0.78v3.901c0,4.733-3.851,8.585-8.584,8.585 s-8.585-3.852-8.585-8.585v-3.901c0-0.431-0.35-0.78-0.78-0.78c-0.432,0-0.78,0.35-0.78,0.78v3.901 c0,5.331,4.134,9.716,9.365,10.116v3.932h-5.463c-0.432,0-0.781,0.349-0.781,0.78c0,0.431,0.35,0.78,0.781,0.78h12.486 c0.431,0,0.78-0.35,0.78-0.78c0-0.432-0.35-0.78-0.78-0.78H20.78v-3.932c5.23-0.4,9.365-4.785,9.365-10.116v-3.901 C30.146,16.06,29.796,15.71,29.364,15.71z"></path>
															<path
																display="inline"
																fill="#010101"
																d="M20,27.025c3.657,0,6.633-2.975,6.633-6.633v-9.76C26.633,6.976,23.657,4,20,4 s-6.633,2.976-6.633,6.633v9.76C13.367,24.051,16.343,27.025,20,27.025z M14.928,10.633c0-2.797,2.275-5.072,5.072-5.072 s5.072,2.275,5.072,5.072v9.76c0,2.797-2.275,5.072-5.072,5.072s-5.072-2.275-5.072-5.072V10.633z"></path>
														</g>
														<g display="none">
															<path
																display="inline"
																fill="#010101"
																d="M22.603,19.604c1.136-0.807,1.88-2.131,1.88-3.626c0-2.452-1.994-4.446-4.444-4.446 c-2.451,0-4.445,1.994-4.445,4.446c0,1.495,0.745,2.82,1.881,3.627l-5.008,14.432c-0.132,0.378,0.068,0.791,0.447,0.924 C12.991,34.986,13.072,35,13.15,35c0.3,0,0.581-0.188,0.684-0.488l1.02-2.933h10.37l1.017,2.933C26.344,34.813,26.626,35,26.927,35 c0.078,0,0.157-0.014,0.237-0.039c0.378-0.133,0.578-0.546,0.446-0.924L22.603,19.604z M20.038,12.991 c1.646,0,2.986,1.34,2.986,2.987c0,1.646-1.34,2.986-2.986,2.986c-1.647,0-2.986-1.34-2.986-2.986 C17.052,14.331,18.391,12.991,20.038,12.991z M20.038,20.423c0.435,0,0.854-0.064,1.252-0.181l1.603,4.622h-5.709l1.604-4.622 C19.185,20.359,19.604,20.423,20.038,20.423z M15.356,30.129l1.324-3.814h6.715l1.325,3.814H15.356z"></path>
															<path
																display="inline"
																fill="#010101"
																d="M14.12,21.617c0.142,0.142,0.327,0.212,0.511,0.212c0.187,0,0.373-0.07,0.515-0.212 c0.282-0.283,0.282-0.743,0-1.025c-1.234-1.231-1.913-2.87-1.913-4.613c0-1.743,0.678-3.381,1.911-4.613 c0.282-0.283,0.282-0.742,0-1.026c-0.285-0.282-0.743-0.282-1.025,0c-1.507,1.506-2.336,3.508-2.336,5.639 C11.782,18.108,12.612,20.111,14.12,21.617z"></path>
															<path
																display="inline"
																fill="#010101"
																d="M12.581,23.154c-1.919-1.916-2.974-4.465-2.974-7.176c0-2.71,1.055-5.259,2.973-7.178 c0.283-0.283,0.283-0.742,0-1.025c-0.284-0.283-0.743-0.282-1.025,0.001c-2.191,2.191-3.398,5.104-3.398,8.202 s1.208,6.011,3.4,8.202c0.142,0.142,0.326,0.212,0.512,0.212s0.372-0.07,0.513-0.212C12.864,23.896,12.864,23.438,12.581,23.154z"></path>
															<path
																display="inline"
																fill="#010101"
																d="M5.982,15.979c0-3.678,1.432-7.137,4.034-9.741c0.283-0.283,0.283-0.742,0-1.025 c-0.283-0.283-0.743-0.283-1.025,0c-2.876,2.877-4.46,6.7-4.46,10.766c0,4.065,1.584,7.889,4.46,10.766 c0.142,0.141,0.327,0.211,0.513,0.211s0.372-0.07,0.513-0.211c0.283-0.284,0.283-0.744,0-1.026 C7.415,23.115,5.982,19.656,5.982,15.979z"></path>
															<path
																display="inline"
																fill="#010101"
																d="M26.768,15.979c0,1.742-0.679,3.38-1.911,4.612c-0.282,0.282-0.282,0.741,0,1.024 c0.142,0.142,0.328,0.213,0.514,0.213c0.185,0,0.37-0.071,0.512-0.213c1.507-1.506,2.337-3.508,2.337-5.637 c0-2.131-0.831-4.134-2.338-5.64c-0.283-0.283-0.741-0.282-1.026,0c-0.282,0.283-0.282,0.742,0.001,1.025 C26.089,12.596,26.768,14.235,26.768,15.979z"></path>
															<path
																display="inline"
																fill="#010101"
																d="M28.445,7.775c-0.285-0.283-0.744-0.282-1.026,0.001c-0.284,0.283-0.284,0.742,0,1.024 c1.917,1.917,2.973,4.466,2.973,7.178c0,2.709-1.055,5.258-2.971,7.178c-0.284,0.282-0.284,0.74,0,1.024 c0.142,0.142,0.328,0.212,0.513,0.212c0.186,0,0.372-0.07,0.513-0.212c2.19-2.192,3.397-5.105,3.397-8.202 C31.844,12.879,30.636,9.966,28.445,7.775z"></path>
															<path
																display="inline"
																fill="#010101"
																d="M31.007,5.212c-0.282-0.282-0.741-0.282-1.025,0c-0.283,0.283-0.283,0.743,0,1.025 c2.603,2.603,4.037,6.063,4.037,9.741c0,3.677-1.434,7.136-4.035,9.74c-0.283,0.283-0.283,0.741,0,1.025 c0.141,0.141,0.327,0.211,0.513,0.211c0.185,0,0.371-0.07,0.513-0.211c2.876-2.879,4.459-6.701,4.459-10.766 C35.468,11.912,33.884,8.089,31.007,5.212z"></path>
														</g>
														<g display="none">
															<path
																display="inline"
																fill="#010101"
																d="M26.197,5c-4.854,0-8.803,3.948-8.803,8.802c0,0.704,0.084,1.402,0.249,2.082L5,28.554V35 h6.979v-3.542h3.542v-3.542h3.541v-3.541h3.056l2.014-2.016c0.674,0.163,1.367,0.244,2.066,0.244c4.853,0,8.803-3.947,8.803-8.801 C35,8.949,31.05,5,26.197,5z M26.197,20.938c-0.705,0-1.403-0.103-2.074-0.307l-0.476-0.144l-2.223,2.221h-4.03v3.542h-3.541v3.541 h-3.541v3.542H6.667v-4.091l12.851-12.875l-0.146-0.479c-0.205-0.674-0.311-1.376-0.311-2.086c0-3.935,3.2-7.136,7.136-7.136 c3.935,0,7.135,3.201,7.135,7.136C33.332,17.737,30.132,20.938,26.197,20.938z"></path>
															<circle
																display="inline"
																fill="#010101"
																cx="27.671"
																cy="12.348"
																r="1.666"></circle>
														</g>
														<path
															display="none"
															fill="#040305"
															d="M5.812,35.5V7.128h6.317V4.5h1.594v2.628h12.402V4.5h1.604v2.628h6.458V35.5H5.812z M32.584,33.896V14.626H7.416v19.269H32.584z M32.584,13.022V8.733h-4.854v1.675h-1.604V8.733H13.723v1.675h-1.594V8.733H7.416 v4.289H32.584z"></path>
														<path
															display="none"
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M22.672,29.195l-7.148,3.457c-0.331-0.021-0.63-0.235-0.951-0.766 L5,6.985c0.013-0.354,0.356-0.697,0.778-0.697l28.958,15.838c0.131,0.141,0.304,0.615,0.256,0.94 c-0.018,0.122-0.063,0.202-0.135,0.239l-6.256,3.024v5.654c0,0.926-0.731,1.685-1.646,1.726L22.672,29.195z M23.732,28.689 l2.346,2.471c0.339,0.345,0.504,0.492,0.673,0.492h0.001c0.115,0,0.261-0.062,0.297-0.363c0.016-0.135,0.018-0.341,0.018-0.622 v-3.22l0.012-0.011l0.012-0.133l-0.014-0.102l-0.007-0.004l-0.003-0.651l-0.003,0.002c0.008-0.487,0.029-1.026,0.029-1.026 l0.005-0.103l-8.106-5.941l-0.256,0.283l5.957,6.661l0.036-0.019l0.786,0.937l0.293,0.328L23.732,28.689z M9.12,9.995 c-0.448,0-0.812,0.363-0.812,0.811l7.175,18.895c0.195,0.325,0.464,0.665,0.957,0.665l6.112-2.922l0.146-0.038l1.488-0.735 l-9.567-10.689l13.229,8.908l3.224-1.551c0.103-0.054,0.224-0.163,0.259-0.392c0.043-0.292-0.086-0.704-0.281-0.898L9.169,9.995 H9.12z"></path>
														<path
															display="none"
															d="M34.285,10H5.714C5.32,10,5,10.319,5,10.714v18.571C5,29.68,5.32,30,5.714,30h28.571 C34.681,30,35,29.68,35,29.285V10.714C35,10.319,34.681,10,34.285,10z M6.429,12.269l8.818,7.068l-8.818,8.124V12.269z M20,21.318 l-12.335-9.89h24.671L20,21.318z M16.374,20.241l3.178,2.548c0.001,0,0.003,0.002,0.004,0.002c0.007,0.006,0.015,0.012,0.021,0.017 c0.013,0.009,0.025,0.019,0.038,0.026c0.008,0.006,0.016,0.01,0.024,0.015c0.014,0.009,0.029,0.017,0.043,0.024 c0.007,0.002,0.013,0.006,0.019,0.01c0.022,0.009,0.045,0.018,0.067,0.025c0.001,0,0.002,0,0.003,0.002 c0.021,0.006,0.044,0.014,0.066,0.017c0.006,0.002,0.011,0.004,0.017,0.004c0.019,0.005,0.037,0.007,0.056,0.009 c0.005,0.002,0.012,0.002,0.017,0.002c0.024,0.004,0.047,0.006,0.071,0.006h0l0,0c0.024,0,0.048-0.002,0.072-0.006 c0.005,0,0.011,0,0.016-0.002c0.02-0.002,0.039-0.004,0.057-0.009c0.006,0,0.012-0.002,0.017-0.004 c0.022-0.003,0.044-0.011,0.066-0.017c0.001-0.002,0.002-0.002,0.003-0.002c0.023-0.008,0.045-0.017,0.067-0.025 c0.007-0.004,0.013-0.008,0.019-0.01c0.015-0.008,0.029-0.016,0.045-0.024c0.008-0.005,0.016-0.009,0.023-0.015 c0.012-0.008,0.025-0.018,0.037-0.026c0.008-0.005,0.016-0.011,0.022-0.017c0.001,0,0.003-0.002,0.004-0.002l3.178-2.548l9.043,8.33 H7.332L16.374,20.241z M24.754,19.337l8.817-7.068v15.175L24.754,19.337z"></path>
														<g display="none">
															<g display="inline">
																<path
																	d="M20.01,5.5C12.008,5.5,5.5,12.005,5.5,20c0,7.997,6.508,14.5,14.51,14.5c2.701,0,5.34-0.75,7.633-2.166 c0.209-0.13,0.354-0.332,0.408-0.568c0.057-0.238,0.016-0.481-0.112-0.689c-0.17-0.273-0.462-0.438-0.782-0.438 c-0.168,0-0.333,0.049-0.477,0.138c-2,1.239-4.307,1.895-6.67,1.895c-6.99,0-12.677-5.687-12.677-12.67 c0-6.984,5.687-12.667,12.677-12.667c4.227,0,8.162,2.095,10.525,5.603c1.17,1.739,1.895,3.758,2.094,5.841 c0.047,0.494,0.152,2.249-0.475,4.098c-0.588,1.732-2.268,2.978-3.943,2.903c-1.047-0.038-1.807-0.407-2.326-1.126 c-1.666-2.338-0.511-7.771,0.074-9.666c0.145-0.472-0.105-0.969-0.571-1.134c-0.199-0.069-2.062-0.694-4.348-0.694 c-1.554,0-2.929,0.292-4.088,0.863c-1.801,0.888-3.064,2.42-3.755,4.554c-0.87,2.684-0.403,5.274,1.249,6.928 c0.991,0.993,2.305,1.537,3.696,1.537c0.549,0,1.096-0.086,1.624-0.258c1.015-0.323,1.929-0.933,2.718-1.811l0.923-1.025 l0.649,1.216c0.101,0.189,0.213,0.371,0.332,0.54c0.846,1.189,2.148,1.846,3.767,1.904c0.063,0.002,0.124,0.002,0.186,0.002 c2.401,0,4.737-1.742,5.552-4.148c0.723-2.141,0.635-4.112,0.563-4.858c-0.229-2.388-1.059-4.699-2.396-6.688 C29.349,7.898,24.846,5.5,20.01,5.5z M18.139,25.214L18.139,25.214c-0.901,0-1.752-0.356-2.397-1.003 c-1.173-1.176-1.466-3.023-0.804-5.069c0.538-1.658,1.456-2.794,2.808-3.473c0.922-0.459,2.026-0.69,3.285-0.69 c0.688,0,1.834,0.153,1.883,0.158l0.981,0.133l-0.156,0.979c-0.013,0.078-0.317,1.959-0.844,4.058 c-0.604,2.413-1.983,4.186-3.69,4.737C18.853,25.157,18.495,25.214,18.139,25.214z"></path>
															</g>
														</g>
														<path
															display="none"
															d="M34.949,21.604L30.081,8.122c-0.106-0.297-0.39-0.496-0.706-0.496h-18.75c-0.315,0-0.598,0.199-0.705,0.496 L5.051,21.604C5.019,21.688,5.001,21.777,5,21.871l0,0c0,0.002,0,0.002,0,0.002l0,0l0,0v0.002l0,0v9.749 c0,0.414,0.336,0.75,0.75,0.75h28.5c0.415,0,0.75-0.336,0.75-0.75v-9.749l0,0v-0.002l0,0l0,0c0,0,0,0,0-0.002l0,0 C35,21.777,34.982,21.688,34.949,21.604z M11.151,9.125H28.85l4.333,12h-1.682l-2.024-6.004c-0.104-0.305-0.389-0.51-0.711-0.51 H11.71c-0.314,0-0.596,0.197-0.704,0.492l-2.207,6.022h-1.98L11.151,9.125z M29.918,21.125h-4.293c-0.283,0-0.543,0.16-0.67,0.414 l-1.668,3.336h-6.573l-1.668-3.336c-0.127-0.254-0.387-0.414-0.671-0.414h-3.978l1.837-5.014h15.992L29.918,21.125z M33.5,30.874 h-27v-8.249h7.412l1.668,3.335c0.127,0.254,0.387,0.414,0.67,0.414h7.5c0.285,0,0.545-0.16,0.672-0.414l1.668-3.335h7.41V30.874z"></path>
														<g display="none">
															<path
																display="inline"
																d="M20,21.569c-0.095,0-0.189-0.021-0.282-0.062L5.434,15.115C5.165,14.995,4.994,14.72,5,14.413 c0.006-0.303,0.191-0.578,0.46-0.683l14.286-5.681C19.828,8.017,19.913,8,20,8c0.086,0,0.172,0.017,0.254,0.049l14.285,5.679 c0.275,0.11,0.455,0.378,0.461,0.683c0.008,0.308-0.163,0.584-0.434,0.704L20.281,21.51C20.192,21.549,20.096,21.569,20,21.569 L20,21.569z M7.626,14.468l12.339,5.522l12.409-5.522L20,9.549L7.626,14.468z"></path>
															<path
																display="inline"
																d="M5.434,20.49c-0.361-0.163-0.53-0.604-0.376-0.983c0.113-0.275,0.372-0.454,0.659-0.454 c0.097,0,0.191,0.021,0.282,0.062l13.967,6.249l14.037-6.249c0.092-0.042,0.186-0.062,0.283-0.062c0.286,0,0.544,0.177,0.656,0.454 c0.155,0.379-0.014,0.82-0.376,0.983L20,27.008L5.434,20.49z"></path>
															<path
																display="inline"
																d="M5.434,25.48c-0.362-0.164-0.531-0.604-0.376-0.981c0.113-0.275,0.372-0.454,0.659-0.454 c0.097,0,0.191,0.021,0.282,0.061l13.967,6.25l14.037-6.25c0.09-0.039,0.186-0.061,0.283-0.061c0.286,0,0.544,0.179,0.656,0.454 c0.155,0.378-0.014,0.819-0.375,0.981L20,32L5.434,25.48z"></path>
														</g>
														<g display="none">
															<path
																display="inline"
																d="M21.768,34c-0.188,0-0.366-0.073-0.502-0.208L6.207,18.733c-0.132-0.133-0.208-0.317-0.208-0.503 L6.041,6.707C6.042,6.317,6.361,6,6.751,6L18.23,6.002c0.19,0,0.368,0.072,0.504,0.207l15.059,15.059 c0.133,0.135,0.208,0.313,0.208,0.503c0,0.188-0.075,0.366-0.21,0.501L22.27,33.792C22.136,33.927,21.957,34,21.768,34L21.768,34z M7.42,17.866l14.347,14.423l10.521-10.519l-14.3-14.298L7.457,7.417L7.42,17.866z"></path>
															<circle
																display="inline"
																cx="11.78"
																cy="11.777"
																r="1.33"></circle>
														</g>
														<g display="none">
															<path
																display="inline"
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M10.738,35.5c-0.462,0-0.531-0.451-0.531-0.721V6.297 c0-0.991,0.806-1.797,1.797-1.797h15.99c0.992,0,1.799,0.806,1.799,1.797v28.482c0,0.229-0.056,0.408-0.16,0.521 c-0.093,0.1-0.225,0.15-0.391,0.15l0,0c-0.34,0-0.754-0.211-1.106-0.563l-8.137-8.138l-8.137,8.138 C11.473,35.277,11.063,35.5,10.738,35.5L10.738,35.5z M26.854,31.674c0.241,0.24,0.491,0.367,0.723,0.367 c0.363,0,0.618-0.313,0.618-0.76V7.297c0-0.662-0.538-1.198-1.199-1.198h-13.99c-0.662,0-1.201,0.537-1.201,1.198v23.984 c0,0.209,0.054,0.391,0.154,0.525c0.113,0.15,0.278,0.234,0.466,0.234c0.23,0,0.48-0.127,0.722-0.367l6.854-6.855L26.854,31.674z"></path>
															<path
																display="inline"
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M25.791,15.35c-0.004,0-0.006-0.006-0.012-0.008 c-0.053-0.023-0.111-0.039-0.176-0.039c-0.023,0-0.041,0.01-0.063,0.012c-1.507-0.037-3.844-0.098-3.844-0.098 s-0.795-2.249-1.296-3.666c-0.012-0.037-0.027-0.068-0.047-0.1c-0.021-0.041-0.043-0.07-0.064-0.082 c-0.076-0.07-0.176-0.117-0.287-0.117s-0.213,0.047-0.287,0.117c-0.021,0.012-0.041,0.041-0.063,0.082 c-0.021,0.031-0.037,0.063-0.049,0.1c-0.501,1.417-1.296,3.666-1.296,3.666s-2.339,0.061-3.842,0.098 c-0.023-0.002-0.043-0.012-0.066-0.012c-0.063,0-0.119,0.016-0.176,0.039c-0.004,0.002-0.004,0.008-0.01,0.008 c-0.138,0.068-0.237,0.21-0.237,0.376c0,0.084,0.031,0.156,0.072,0.223c0,0,0,0,0.002,0c0.035,0.059,0.081,0.102,0.138,0.137 c1.195,0.916,3.071,2.354,3.071,2.354s-0.662,2.239-1.092,3.681c-0.035,0.061-0.059,0.127-0.059,0.199 c0,0.236,0.191,0.428,0.424,0.428c0.127,0,0.238-0.059,0.314-0.146c0.303-0.207,0.644-0.441,0.989-0.68l2.165-1.489l2.167,1.489 c0.346,0.238,0.685,0.473,0.987,0.68c0.078,0.088,0.188,0.146,0.313,0.146c0.234,0,0.424-0.191,0.424-0.428 c0-0.072-0.023-0.139-0.055-0.199c-0.43-1.442-1.096-3.681-1.096-3.681s1.878-1.438,3.073-2.354 c0.057-0.035,0.105-0.078,0.138-0.137c0.043-0.066,0.072-0.139,0.072-0.223C26.026,15.561,25.93,15.418,25.791,15.35z M21.428,17.931l0.754,2.533l-2.179-1.499l-2.181,1.499l0.754-2.533l-2.099-1.611l2.644-0.066l0.882-2.497l0.88,2.497l2.647,0.066 L21.428,17.931z"></path>
														</g>
														<path
															display="none"
															d="M29.387,34.986c-0.145,0-0.289-0.037-0.416-0.112L20,29.544l-8.977,5.33 c-0.124,0.075-0.267,0.112-0.412,0.112c-0.174,0-0.34-0.053-0.481-0.158c-0.262-0.191-0.383-0.521-0.307-0.84l2.389-10l-7.926-6.717 c-0.251-0.213-0.348-0.554-0.246-0.866c0.101-0.313,0.377-0.532,0.704-0.56l10.45-0.862l4.062-9.479 C19.383,5.208,19.675,5.014,20,5.014c0.324,0,0.617,0.194,0.746,0.493l4.06,9.477l10.45,0.862c0.329,0.028,0.605,0.247,0.706,0.56 c0.1,0.314,0.003,0.653-0.248,0.864l-7.928,6.719l2.389,10.002c0.076,0.316-0.046,0.646-0.309,0.838 C29.725,34.934,29.559,34.986,29.387,34.986L29.387,34.986z M16.489,16.069c-0.117,0.277-0.377,0.464-0.677,0.488l-8.986,0.742 l6.813,5.773c0.232,0.199,0.334,0.508,0.264,0.807l-2.06,8.624l7.744-4.599c0.123-0.073,0.266-0.113,0.412-0.113 s0.289,0.04,0.412,0.113l7.742,4.599l-2.06-8.624c-0.071-0.299,0.029-0.607,0.265-0.807l6.814-5.773l-8.988-0.742 c-0.3-0.024-0.56-0.211-0.677-0.486L20,7.882L16.489,16.069z"></path>
														<g display="none">
															<path
																display="inline"
																d="M33.125,35H6.875c-0.519,0-0.938-0.421-0.938-0.938v-5.75c0-0.519,0.419-0.938,0.938-0.938 c0.518,0,0.938,0.42,0.938,0.938v4.813h24.375v-4.813c0-0.519,0.419-0.938,0.938-0.938c0.518,0,0.938,0.42,0.938,0.938v5.75 C34.063,34.579,33.643,35,33.125,35"></path>
															<path
																display="inline"
																d="M27.576,14.153c-0.234,0-0.47-0.087-0.651-0.264L20.041,7.24l-6.884,6.649 c-0.371,0.36-0.965,0.351-1.325-0.021c-0.359-0.374-0.349-0.966,0.024-1.326l7.535-7.277c0.362-0.354,0.939-0.354,1.301,0 l7.535,7.277c0.373,0.36,0.383,0.952,0.023,1.326C28.065,14.057,27.821,14.153,27.576,14.153"></path>
															<path
																display="inline"
																d="M20.041,29.248c-0.518,0-0.937-0.42-0.937-0.937V5.937C19.104,5.421,19.523,5,20.041,5 c0.519,0,0.938,0.421,0.938,0.937v22.375C20.979,28.828,20.56,29.248,20.041,29.248"></path>
														</g>
														<g display="none">
															<path
																display="inline"
																d="M33.125,35H6.875c-0.519,0-0.938-0.42-0.938-0.938v-5.75c0-0.518,0.419-0.938,0.938-0.938 c0.518,0,0.937,0.421,0.937,0.938v4.813h24.375v-4.813c0-0.518,0.419-0.938,0.938-0.938c0.518,0,0.938,0.421,0.938,0.938v5.75 C34.063,34.58,33.643,35,33.125,35"></path>
															<path
																display="inline"
																d="M20.042,29.249c-0.235,0-0.469-0.089-0.651-0.265l-7.535-7.277c-0.373-0.36-0.383-0.954-0.023-1.326 c0.358-0.372,0.954-0.384,1.325-0.023l6.884,6.649l6.884-6.649c0.372-0.36,0.968-0.349,1.324,0.023 c0.36,0.372,0.351,0.966-0.022,1.326l-7.534,7.277C20.512,29.16,20.277,29.249,20.042,29.249"></path>
															<path
																display="inline"
																d="M20.042,29.249c-0.518,0-0.938-0.421-0.938-0.939V5.937C19.104,5.418,19.524,5,20.042,5 c0.519,0,0.938,0.418,0.938,0.937V28.31C20.979,28.828,20.561,29.249,20.042,29.249"></path>
														</g>
														<g display="none">
															<path
																display="inline"
																d="M33.732,10.556h-8.844c-0.043-1.488-1.26-2.686-2.758-2.686H6.267C4.741,7.87,3.5,9.11,3.5,10.637v10.405 c0,1.527,1.241,2.768,2.767,2.768h1.292l4.637,5.353c0.156,0.181,0.382,0.282,0.619,0.282c0.094,0,0.19-0.016,0.287-0.051 c0.318-0.119,0.532-0.428,0.532-0.768V23.81h1.478c0.043,1.487,1.26,2.687,2.758,2.687h8.498v4.815c0,0.34,0.214,0.648,0.534,0.769 c0.095,0.034,0.19,0.05,0.284,0.05c0.236,0,0.463-0.102,0.619-0.281l4.637-5.353h1.291c1.526,0,2.768-1.241,2.768-2.768V13.323 C36.5,11.796,35.259,10.556,33.732,10.556z M15.102,13.323v8.848h-2.288c-0.452,0-0.819,0.368-0.819,0.819v3.44l-3.443-3.978 c-0.157-0.178-0.382-0.282-0.646-0.282c0,0-1.638,0-1.638,0c-0.623,0-1.129-0.505-1.129-1.129V10.637 c0-0.622,0.507-1.13,1.129-1.13h15.864c0.594,0,1.068,0.466,1.111,1.049h-5.374C16.343,10.556,15.102,11.796,15.102,13.323z M34.862,23.729c0,0.623-0.507,1.129-1.13,1.129h-1.664c-0.236,0-0.463,0.102-0.621,0.282l-3.442,3.977v-3.44 c0-0.451-0.368-0.818-0.819-0.818h-9.317c-0.622,0-1.128-0.506-1.128-1.129V13.323c0-0.623,0.506-1.13,1.128-1.13h15.864 c0.623,0,1.13,0.507,1.13,1.13V23.729z"></path>
															<path
																display="inline"
																d="M32.841,14.703h-14.08c-0.452,0-0.819,0.367-0.819,0.818s0.367,0.819,0.819,0.819h14.08 c0.452,0,0.82-0.368,0.82-0.819S33.293,14.703,32.841,14.703z"></path>
															<path
																display="inline"
																d="M32.841,17.706h-14.08c-0.452,0-0.819,0.367-0.819,0.819c0,0.451,0.367,0.819,0.819,0.819h14.08 c0.452,0,0.82-0.368,0.82-0.819C33.661,18.073,33.293,17.706,32.841,17.706z"></path>
															<path
																display="inline"
																d="M25.426,20.71h-6.665c-0.452,0-0.819,0.367-0.819,0.818s0.367,0.819,0.819,0.819h6.665 c0.451,0,0.818-0.368,0.818-0.819S25.877,20.71,25.426,20.71z"></path>
														</g>
														<g>
															<g>
																<path
																	d="M31.228,5H8.772C6.762,5,5.125,6.637,5.125,8.646v14.731c0,2.011,1.636,3.646,3.647,3.646H21.07v7.086 c0,0.372,0.23,0.705,0.578,0.833C21.751,34.981,21.855,35,21.96,35c0.253,0,0.499-0.107,0.673-0.307l6.644-7.669h1.951 c2.011,0,3.647-1.636,3.647-3.646V8.646C34.875,6.637,33.238,5,31.228,5z M33.096,23.378c0,1.028-0.838,1.867-1.868,1.867 c0,0-2.303,0-2.315,0c-0.264-0.013-0.529,0.093-0.716,0.306l-5.347,6.173v-5.589c0-0.492-0.398-0.89-0.89-0.89H8.772 c-1.029,0-1.866-0.839-1.866-1.867V8.646c0-1.028,0.837-1.865,1.866-1.865h22.455c1.03,0,1.868,0.837,1.868,1.865V23.378 L33.096,23.378L33.096,23.378z"></path>
																<path
																	d="M29.965,10.87h-19.93c-0.491,0-0.89,0.399-0.89,0.892c0,0.49,0.398,0.89,0.89,0.89h19.93c0.492,0,0.891-0.399,0.891-0.89 C30.855,11.27,30.457,10.87,29.965,10.87z"></path>
																<path
																	d="M29.965,15.123h-19.93c-0.491,0-0.89,0.397-0.89,0.89c0,0.489,0.398,0.89,0.89,0.89h19.93c0.492,0,0.891-0.4,0.891-0.89 C30.855,15.521,30.457,15.123,29.965,15.123z"></path>
																<path
																	d="M19.469,19.373h-9.434c-0.491,0-0.89,0.399-0.89,0.89c0,0.492,0.398,0.892,0.89,0.892h9.434 c0.491,0,0.89-0.399,0.89-0.892C20.358,19.772,19.96,19.373,19.469,19.373z"></path>
															</g>
														</g>
													</g>
												</svg>

												<span class="text-sm leading-5 font-normal"
													>Feature Request</span
												>
											</div>
										</div>

										<div
											@click="openBeamerRoadmap"
											class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100">
											<div
												class="flex flex-row items-center space-x-4">
												<svg
													viewBox="0 0 24 24"
													class="w-5 h-5"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
													stroke="#9ca3af">
													<g
														id="SVGRepo_bgCarrier"
														stroke-width="0"></g>
													<g
														id="SVGRepo_tracerCarrier"
														stroke-linecap="round"
														stroke-linejoin="round"></g>
													<g id="SVGRepo_iconCarrier">
														<path
															d="M14.78 20H9.78C7.98 20 4.58 19.09 4.58 15.64C4.58 12.19 7.98 11.28 9.78 11.28H14.22C14.37 11.28 17.92 11.23 17.92 8.42C17.92 5.61 14.37 5.56 14.22 5.56H9.22C9.02109 5.56 8.83032 5.48098 8.68967 5.34033C8.54902 5.19968 8.47 5.00891 8.47 4.81C8.47 4.61109 8.54902 4.42032 8.68967 4.27967C8.83032 4.13902 9.02109 4.06 9.22 4.06H14.22C16.02 4.06 19.42 4.97 19.42 8.42C19.42 11.87 16.02 12.78 14.22 12.78H9.78C9.63 12.78 6.08 12.83 6.08 15.64C6.08 18.45 9.63 18.5 9.78 18.5H14.78C14.9789 18.5 15.1697 18.579 15.3103 18.7197C15.451 18.8603 15.53 19.0511 15.53 19.25C15.53 19.4489 15.451 19.6397 15.3103 19.7803C15.1697 19.921 14.9789 20 14.78 20Z"
															fill="#9ca3af"></path>
														<path
															d="M6.44 8.31C5.74314 8.30407 5.06363 8.09202 4.48708 7.70056C3.91054 7.30909 3.46276 6.75573 3.20018 6.11021C2.93759 5.46469 2.87195 4.75589 3.01153 4.07312C3.1511 3.39036 3.48965 2.76418 3.9845 2.2735C4.47935 1.78281 5.10837 1.44958 5.79229 1.31579C6.47622 1.182 7.18444 1.25363 7.82771 1.52167C8.47099 1.78971 9.02054 2.24215 9.40711 2.82199C9.79368 3.40182 9.99998 4.08311 10 4.78C10 5.2461 9.90773 5.70759 9.72846 6.13783C9.54919 6.56808 9.28648 6.95856 8.95551 7.28675C8.62453 7.61494 8.23184 7.87433 7.80009 8.04995C7.36834 8.22558 6.90609 8.31396 6.44 8.31ZM6.44 2.75C6.04444 2.75 5.65776 2.86729 5.32886 3.08706C4.99996 3.30682 4.74362 3.61918 4.59224 3.98463C4.44087 4.35008 4.40126 4.75221 4.47843 5.14018C4.5556 5.52814 4.74609 5.8845 5.02579 6.16421C5.3055 6.44391 5.66186 6.6344 6.04982 6.71157C6.43779 6.78874 6.83992 6.74913 7.20537 6.59776C7.57082 6.44638 7.88318 6.19003 8.10294 5.86114C8.32271 5.53224 8.44 5.14556 8.44 4.75C8.44 4.48735 8.38827 4.22728 8.28776 3.98463C8.18725 3.74198 8.03993 3.5215 7.85422 3.33578C7.6685 3.15007 7.44802 3.00275 7.20537 2.90224C6.96272 2.80173 6.70265 2.75 6.44 2.75Z"
															fill="#9ca3af"></path>
														<path
															d="M17.56 22.75C16.8614 22.752 16.1779 22.5466 15.5961 22.1599C15.0143 21.7733 14.5603 21.2227 14.2916 20.5778C14.0229 19.933 13.9515 19.2229 14.0866 18.5375C14.2217 17.8521 14.5571 17.2221 15.0504 16.7275C15.5437 16.2328 16.1726 15.8956 16.8577 15.7586C17.5427 15.6215 18.253 15.6909 18.8986 15.9577C19.5442 16.2246 20.0961 16.6771 20.4844 17.2578C20.8727 17.8385 21.08 18.5214 21.08 19.22C21.08 20.1545 20.7095 21.0508 20.0496 21.7125C19.3898 22.3743 18.4945 22.7473 17.56 22.75ZM17.56 17.19C17.1644 17.19 16.7778 17.3073 16.4489 17.5271C16.12 17.7468 15.8636 18.0592 15.7122 18.4246C15.5609 18.7901 15.5213 19.1922 15.5984 19.5802C15.6756 19.9681 15.8661 20.3245 16.1458 20.6042C16.4255 20.8839 16.7819 21.0744 17.1698 21.1516C17.5578 21.2287 17.9599 21.1891 18.3254 21.0377C18.6908 20.8864 19.0032 20.63 19.2229 20.3011C19.4427 19.9722 19.56 19.5856 19.56 19.19C19.56 18.6596 19.3493 18.1508 18.9742 17.7758C18.5991 17.4007 18.0904 17.19 17.56 17.19Z"
															fill="#9ca3af"></path>
													</g>
												</svg>
												<span class="text-sm leading-5 font-normal"
													>Product Roadmap</span
												>
											</div>
										</div>

										<!-- <nuxt-link :to="$config.public.routes.Api"
                        class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100" role="menuitem" tabindex="-1">
                        <div class="flex flex-row items-center space-x-4">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                              d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                              clip-rule="evenodd" />
                          </svg>
                          <span class="text-sm leading-5 font-normal">API</span>
                        </div>
                      </nuxt-link> -->

										<!-- <nuxt-link :to="$config.public.routes.Api"
                        class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100" role="menuitem" tabindex="-1">
                        <div class="flex flex-row items-center space-x-4">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                              d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                              clip-rule="evenodd" />
                          </svg>
                          <span class="text-sm leading-5 font-normal">API</span>
                        </div>
                      </nuxt-link> -->

										<a
											target="_blank"
											rel="noreferrer"
											href="https://docs.sendx.io/"
											class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
											role="menuitem"
											tabindex="-1">
											<div
												class="flex flex-row items-center space-x-4">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="h-5 w-5 text-gray-400"
													viewBox="0 0 20 20"
													fill="currentColor">
													<path
														fill-rule="evenodd"
														d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
														clip-rule="evenodd" />
												</svg>
												<span class="text-sm leading-5 font-normal"
													>API</span
												>
											</div>
										</a>
									</div>

									<div class="flex flex-col">
										<div
											@click.stop="switchTeamClickedMobile"
											class="cursor-pointer text-sm px-4 py-3 text-gray-700 hover:bg-gray-100 flex flex-row justify-between"
											role="menuitem"
											tabindex="-1">
											<div
												class="flex flex-row items-center space-x-4">
												<svg
													class="h-5 w-5 text-gray-400"
													viewBox="0 0 16 18"
													fill="currentColor"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M4.81152 5.11788L14.8115 5.11788M14.8115 5.11788L11.4782 1.78455M14.8115 5.11788L11.4782 8.45121M11.4782 13.4512L1.47819 13.4512M1.47819 13.4512L4.81152 16.7845M1.47819 13.4512L4.81152 10.1179"
														stroke="#9CA3AF"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round" />
												</svg>
												<span class="text-sm leading-5 font-normal"
													>Switch Team</span
												>
											</div>
											<div>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="h-5 w-5 text-gray-400"
													viewBox="0 0 20 20"
													fill="currentColor">
													<path
														fill-rule="evenodd"
														d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
														clip-rule="evenodd" />
												</svg>
											</div>
										</div>
									</div>

									<div class="flex flex-col">
										<div
											@click.stop="signOut"
											class="cursor-pointer text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
											role="menuitem"
											tabindex="-1">
											<div
												class="flex flex-row items-center space-x-4">
												<svg
													class="h-5 w-5 text-gray-400"
													viewBox="0 0 18 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M8.3112 11.6179L4.97786 8.28459M4.97786 8.28459L8.3112 4.95125M4.97786 8.28459L16.6445 8.28459M12.4779 11.6179V12.4513C12.4779 13.832 11.3586 14.9513 9.97786 14.9513H4.14453C2.76382 14.9513 1.64453 13.832 1.64453 12.4513V4.11792C1.64453 2.73721 2.76382 1.61792 4.14453 1.61792H9.97786C11.3586 1.61792 12.4779 2.73721 12.4779 4.11792V4.95125"
														stroke="#9CA3AF"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round" />
												</svg>

												<span class="text-sm leading-5 font-normal"
													>Sign out</span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</transition>
						<transition
							enter-active-class="transition ease-out duration-100"
							enter-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95">
							<div
								v-if="switchDropDownOpenMobile"
								class="z-20 absolute bottom-20 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
								role="menu"
								aria-orientation="vertical"
								aria-labelledby="user-menu-button"
								tabindex="-1">
								<div class="h-[40vh] overflow-y-auto">
									<div v-for="(sa, ind) in memTeams">
										<div
											@click.stop="switchTeam(sa.id, sa.name)"
											class="flex flex-col divide-y divide-y-gray-50 cursor-pointer">
											<div class="relative group">
												<div
													class="flex justify-between"
													:class="[
														sa.id === currentTeam?.id
															? 'bg-green-200'
															: 'hover:bg-gray-100',
													]">
													<div
														class="px-4 py-2 flex space-x-4 items-center">
														<div>
															<!-- <span class="inline-block relative">
                                  <img class="h-10 w-10 rounded-full"
                                    :class="[sa.id === activeTeam?.id ? 'border-2 border-blue-900' : '']"
                                    :src="sa.avatarUrl != '' ? sa.avatarUrl : tempAvatarUrl" alt="">
                                  <span class="absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white"
                                    :class="[sa.id === activeTeam?.id ? 'bg-green-400' : 'bg-red-400']"></span>
                                </span> -->
														</div>
														<div
															class="flex flex-col justify-between">
															<div
																class="text-gray-800 leading-5"
																:class="[
																	sa.id === currentTeam?.id
																		? 'font-semibold text-md'
																		: 'text-sm font-medium',
																]">
																{{ sa.name }}
															</div>
															<div
																class="text-gray-400 leading-5 text-xs"
																:class="[
																	sa.id === currentTeam?.id
																		? 'font-semibold'
																		: 'font-medium',
																]">
																{{
																	sa.companyName !== ""
																		? sa.companyName
																		: "-"
																}}
															</div>
														</div>
													</div>
													<div
														v-if="
															store.checkVisibilitity({
																entity: AppEntities.Team,
															}) && sa.id !== currentTeam?.id
														"
														@click.stop="teamMenuCliked(ind)"
														class="text-black mr-2 flex items-center">
														<svg
															class="h-6 w-6"
															viewBox="0 0 24 24"
															fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<g
																id="SVGRepo_bgCarrier"
																stroke-width="0"></g>
															<g
																id="SVGRepo_tracerCarrier"
																stroke-linecap="round"
																stroke-linejoin="round"></g>
															<g id="SVGRepo_iconCarrier">
																<path
																	d="M12 12H12.01M12 6H12.01M12 18H12.01M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18ZM13 6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6Z"
																	stroke="#000000"
																	stroke-width="2"
																	stroke-linecap="round"
																	stroke-linejoin="round"></path>
															</g>
														</svg>
													</div>

													<transition
														enter-active-class="transition ease-out duration-100"
														enter-class="transform opacity-0 scale-95"
														enter-to-class="transform opacity-100 scale-100"
														leave-active-class="transition ease-in duration-75"
														leave-class="transform opacity-100 scale-100"
														leave-to-class="transform opacity-0 scale-95">
														<div
															v-if="ind === teamMenuOpenId"
															class="z-20 origin-top-right absolute right-0 mb-2 mt-12 w-36 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
															role="menu"
															aria-orientation="vertical"
															aria-labelledby="user-menu-button"
															tabindex="-1">
															<div
																v-if="
																	store.checkVisibilitity({
																		entity: AppEntities.Team,
																	})
																">
																<div
																	@click.stop="
																		deleteTeamClicked(sa.id)
																	"
																	class="py-1 px-2 hover:bg-gray-100 text-sm text-red-500">
																	Delete
																</div>
															</div>
														</div>
													</transition>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<button
										@click.stop.stop.prevent="addTeamClicked"
										type="button"
										class="hover:bg-gray-100 inline-flex items-center justify-center px-4 py-2 w-full border border-transparent shadow-sm text-sm font-semibold rounded-md text-gray-800 bg-white outline-none">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="-ml-1 mr-2 h-5 w-5"
											viewBox="0 0 20 20"
											fill="currentColor">
											<path
												fill-rule="evenodd"
												d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
												clip-rule="evenodd" />
										</svg>
										Add Team
									</button>
								</div>
							</div>
						</transition>
					</div>
				</div>
			</transition>
		</nav>

		<SettingTeamSidebar
			:open="addTeamSidebarOpen"
			@closeTeamSidebar="closeTeamSidebar" />
		<SettingPaymentUpgradeModal
			:active="isComponentModalActive"
			:selectedPrice="selectedPrice"
			:selectedMonthlyPrice="selectedMonthlyPrice"
			:isDiscountAvailabel="isDiscountAvailabel"
			:discount="discount"
			:isMonthlyBillingType="isMonthlyBillingType"
			:selectedOption="selectedOption"
			:options="options"
			@cancelModalClicked="cancelModalClicked"
			@handlePurchase="handlePurchase"
			@toggleBillingType="(e) => toggleBillingType(e)"
			@handleDropdownInput="(e) => handleDropdownInput(e)" />

		<DeleteModalForm
			:active="isDeleteComponentModalActive"
			:entityName="entityName"
			:elementId="elementId"
			:elementName="elementName"
			:deleteLoader="teamLoader"
			@cancelModalClicked="cancelDeleteModalClicked"
			@deleteModalClicked="(value) => deleteTeam(value)" />
	</div>
</template>

<script setup>
import {
	Routes,
	AccountState,
	appFrontendUrl,
	stripePublicKey,
	AppEntities,
	AddOns,
} from "~/constants";
import { useEventBus } from "@vueuse/core";
import {
	formatUtcOffset,
	getTimeZoneGroups,
	getTimeZoneInfo,
} from "~/data/timezone";
import { toLower } from "lodash";
import moment from "moment-timezone";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import SearchDropdown from "./SearchDropdown.vue";

const bus = useEventBus("resetData");
const route = useRoute();

const dropdowns = ref({
	None: "none",
	Campaigns: "campaigns",
	Audience: "audience",
	Workflows: "workflows",
	LeadCapture: "lead-capture",
});

const switchDropDownOpenMobile = ref(false);

const entityName = ref("Team");
const elementId = ref(0);
const elementName = ref("");
const isDeleteComponentModalActive = ref(false);
const teamLoader = ref(false);
const store = useGlobalStore();
const settingStore = useSettingStore();
const contactStore = useAudienceContactStore();
const profileDropDownOpen = ref(false);
const mobileMenuOpen = ref(false);
const activeDropdown = ref(dropdowns.value.None);
const switchDropDownOpen = ref(false);
const searchDropDownOpen = ref(false);
const searchDropDownOpenMobile = ref(false);
const searchValue = ref("");
const queryInProgress = ref(false);
const filteredContact = ref(null);
const activeTeam = ref({});
const member = computed(() => store.$state.authInfo.member);
const addTeamSidebarOpen = ref(false);
const teamMenuOpenId = ref(-1);
const teamMenuOpen = ref(false);
let now = Date.now();
const tempAvatarUrl =
	"https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";

const profileDropDownOpenMobile = ref(false);
const cookies = ref("");

const currentTeam = computed(() => {
	return store.currentTeam
})

const addOnStore = useAddOnStore();

const isShortcutKeyEnabled = computed(() => {
  return addOnStore.isAddOnEnabled({entity: AddOns.SHORTCUTS})
});

const pastelColors = [
	"#FFB7C5", // Light Pink
	"#FFDAB9", // Peach
	"#FFFACD", // Lemon Chiffon
	"#E6E6FA", // Lavender
	"#C1E1C1", // Mint Green
	"#F0E68C", // Khaki
	"#FFD1DC", // Pink Lace
	"#FFCCE5", // Bubblegum Pink
	"#ACE7FF", // Powder Blue
	"#FFECB3", // Light Goldenrod
];

const getRandomColor = computed(() => {
	return pastelColors[Math.floor(Math.random() * 10)];
});

const handleProfileClick = () => {
	profileDropDownOpenMobile.value = !profileDropDownOpenMobile.value;
};

const teamMenuCliked = (ind) => {
	if (teamMenuOpen.value === false) {
		teamMenuOpenId.value = ind;
		teamMenuOpen.value = true;
	} else {
		if (teamMenuOpenId.value !== -1 && teamMenuOpenId.value !== ind) {
			teamMenuOpenId.value = ind;
			teamMenuOpen.value = true;
		} else {
			teamMenuOpenId.value = -1;
			teamMenuOpen.value = false;
		}
	}
};

const cancelDeleteModalClicked = (val) => {
	isDeleteComponentModalActive.value = false;
	if (val) {
		store.globalSpinnerStart();
		resetData().finally(() => {
			store.globalSpinnerStop();
		});
	}
};

const deleteTeam = async (id) => {
	try {
		teamLoader.value = true;
		const res = await store.deleteTeam({ sid: id });
		toast("Team deleted successfully", {
			theme: "auto",
			type: "success",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
		});
		cancelDeleteModalClicked(true);
	} catch (err) {
		console.log("got error while deleting team : ", err);
		if (err.data?.status === "403") {
			toast("Deleting team from different account not allowed. ", {
				theme: "auto",
				type: "error",
				hideProgressBar: false,
				autoClose: 3000,
				transition: "slide",
			});
		} else {
			toast("Error occurred while deleting team :(", {
				theme: "auto",
				type: "error",
				hideProgressBar: false,
				autoClose: 3000,
				transition: "slide",
			});
		}
		cancelDeleteModalClicked(false);
	} finally {
		teamLoader.value = false;
	}
};

const deleteTeamClicked = (id) => {
	memTeams.value.forEach((ns, index) => {
		if (ns.id === id) {
			elementName.value = ns.name;
		}
	});
	elementId.value = id;
	teamMenuCliked(-1);
	isDeleteComponentModalActive.value = true;
};

const memTeams = computed(() => {
	const data = store.getMemberTeams;
	if (data) {
		return data;
	}
	return false;
});

function getInitials(input) {
	if (typeof input !== "string" || input.trim() === "") {
		return "";
	}

	const words = input.trim().split(/\s+/);

	// If it's a single word
	if (words.length === 1) {
		const singleWord = words[0];
		// Extract the first letter and any subsequent capital letters, ensuring only letters are included
		const initials = singleWord
			.split("")
			.filter(
				(char, index) =>
					(index === 0 || char === char.toUpperCase()) &&
					/[a-zA-Z]/.test(char)
			)
			.join("");
		return initials.toUpperCase().slice(0, 2); // Get the first two letters
	}

	// If it's two or more words, get initials of the first two words
	const firstTwoWordsInitials = words
		.slice(0, 2)
		.map((word) => word[0])
		.join("");
	return firstTwoWordsInitials.toUpperCase();
}

const isAccountPaid = computed(() => {
	const tier = store.$state?.authInfo?.account?.Tier ?? 0;
	const showBillingTab =
		store.$state?.authInfo?.account?.ShowBillingTab ?? true;
	if (tier !== 0 && tier !== 4 && tier !== 5 && showBillingTab) {
		return true;
	} else {
		return false;
	}
});

function openBeamerIdeas() {
	if (window.Beamer) {
		window.Beamer.showIdeas(true);
	} else {
		console.log("Beamer is not loaded yet.");
	}
	closeDropDown();
}

function openBeamerRoadmap() {
	if (window.Beamer) {
		window.Beamer.showRoadmap();
	} else {
		console.log("Beamer is not loaded yet.");
	}
	closeDropDown();
}

const addTeamClicked = () => {
	addTeamSidebarOpen.value = true;
};

const closeTeamSidebar = () => {
	addTeamSidebarOpen.value = false;
	switchDropDownOpen.value = false;
	resetData();
};

const hideDropdownsAndRouteToDashboard = async () => {
	hideDropdowns();
	await navigateTo(Routes.Dashboard);
};

const routeToHome = async () => {
	await navigateTo(Routes.Home);
};

const signOut = async () => {
	store.globalSpinnerStart();
	await store.signOut();
	await navigateTo(Routes.Login);
	store.globalSpinnerStop();
};

const closeDropDown = () => {
	profileDropDownOpen.value = false;
	switchDropDownOpen.value = false;
};

const closeDropDownMobile = () => {
	console.log("closeDropDownMobile");
	profileDropDownOpenMobile.value = false;
	switchDropDownOpenMobile.value = false;
};

const closeMobileMenu = () => {
	mobileMenuOpen.value = false;
};

const toggleDropdown = (dd) => {
	activeDropdown.value =
		activeDropdown.value === dd ? dropdowns.value.None : dd;
};

const hideDropdowns = () => {
	activeDropdown.value = dropdowns.value.None;
};

const switchTeamClicked = () => {
	profileDropDownOpen.value = false;
	teamMenuOpenId.value = -1;
	switchDropDownOpen.value = !switchDropDownOpen.value;
};

const switchTeamClickedMobile = () => {
	profileDropDownOpenMobile.value = false;
	teamMenuOpenId.value = -1;
	switchDropDownOpenMobile.value = !switchDropDownOpenMobile.value;
};

const closeContactSearch = () => {
	searchDropDownOpen.value = false;
	filteredContact.value = null;
	searchValue.value = "";
};

const closeContactSearchMobile = () => {
	searchDropDownOpenMobile.value = false;
	filteredContact.value = null;
	searchValue.value = "";
};

const contactSearchClicked = (e) => {
	console.log("contactSearchClicked", e)
	store.setResource({ resource: "showSearch", value: !store.$state.showSearch });
	store.setResource({ resource: "searchTabSelected", value: 2 });
	searchDropDownOpen.value = !searchDropDownOpen.value;
};

const videoSearchClicked = () => {
	store.setResource({ resource: "showSearch", value: !store.$state.showSearch });
	store.setResource({ resource: "searchTabSelected", value: 1 });
	
	searchDropDownOpen.value = !searchDropDownOpen.value;
}

const contactSearchClickedMobile = () => {
	searchDropDownOpenMobile.value = !searchDropDownOpenMobile.value;
};

const isEmail = () => {
	var regex =
		/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	if (!regex.test(searchValue.value)) {
		return false;
	} else {
		return true;
	}
};

const searchContact = async () => {
	if (isEmail()) {
		if (queryInProgress.value === false) {
			queryInProgress.value = true;

			try {
				const res = await contactStore.fetchContactByEmail({
					search: searchValue.value.toLowerCase(),
				});
				filteredContact.value = [];
				if (res !== null) {
					filteredContact.value.push(res);
				}

				queryInProgress.value = false;
			} catch (err) {
				toast("Error occurred while getting contact by email", {
					theme: "auto",
					type: "error",
					hideProgressBar: false,
					autoClose: 3000,
					transition: "slide",
				});
				searchValue.value = "";
				queryInProgress.value = false;
				filteredContact.value = null;
			}
		} else {
			toast("We are still fetching results of your last search.", {
				theme: "auto",
				type: "warning",
				hideProgressBar: false,
				autoClose: 3000,
				transition: "slide",
			});
		}
	} else {
		toast("Please enter a valid email", {
			theme: "auto",
			type: "error",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
		});
	}
};

const gotoContact = async (id) => {
	closeContactSearch();
	closeMobileMenu();
	await navigateTo(`${Routes.Audience.Contact.Info.Home}/${id}`);
};

const getShortcutKeys = async () => {
	try {
		const data = await store.getShortcutKeys()
		console.log(data, "shortcut keys");
	} catch (error) {
		console.log(error);
	}
}

const switchTeam = async (id, name) => {

	if (id === currentTeam.value?.id) {
		return;
	}
	store.globalSpinnerStart();
	try {
		await store.switchTeam({ sid: id });
		closeSwitchDropDown();
		if (route.name === "dashboard") {
			bus.emit();
		} else {
			await navigateTo(Routes.Dashboard);
		}

		await resetData();

		toast(`Successfully switched to team ${name}`, {
			theme: "auto",
			type: "success",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
			position: "bottom-right",
		});
	} catch (error) {
		console.error("An error occurred while switching team:", error);
		toast("An error occurred while switching team", {
			theme: "auto",
			type: "warning",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
		});
	} finally {
		store.globalSpinnerStop();
	}
	getShortcutKeys();
};

const closeSwitchDropDown = () => {
	switchDropDownOpen.value = false;
};

//        ----- Billing Code --------       //
const selectedOption = ref(0);
const isMonthlyBillingType = ref(true);
const monthlyContactDiscount = ref(0);
const yearlyContactDiscount = ref(25);
const yearlyDefaultDiscount = ref(false);
const businessPriceAmount = ref(999);
const totalAmount = ref(999);
const isComponentModalActive = ref(false);
const description = ref("Business Plan ( 1000 contacts )");
const label = ref("Upgrade for");
const paymentType = ref("Upgrade");

const options = [
	{ id: 0, contact: 1000 },
	{ id: 1, contact: 2500 },
	{ id: 2, contact: 5000 },
	{ id: 3, contact: 10000 },
	{ id: 4, contact: 15000 },
	{ id: 5, contact: 20000 },
	{ id: 6, contact: 25000 },
	{ id: 7, contact: 30000 },
	{ id: 8, contact: 35000 },
	{ id: 9, contact: 40000 },
	{ id: 10, contact: 45000 },
	{ id: 11, contact: 50000 },
	{ id: 12, contact: 60000 },
	{ id: 13, contact: 70000 },
	{ id: 14, contact: 80000 },
	{ id: 15, contact: 90000 },
	{ id: 16, contact: 100000 },
	{ id: 17, contact: 120000 },
	{ id: 18, contact: 140000 },
	{ id: 19, contact: 160000 },
	{ id: 20, contact: 180000 },
	{ id: 21, contact: 200000 },
	{ id: 22, contact: 250000 },
	{ id: 23, contact: 300000 },
	{ id: 24, contact: 350000 },
	{ id: 25, contact: 400000 },
	{ id: 26, contact: 450000 },
	{ id: 27, contact: 500000 },
	{ id: 28, contact: 600000 },
	{ id: 29, contact: 800000 },
	{ id: 30, contact: 1000000 },
	{ id: 31, contact: 1500000 },
	{ id: 32, contact: 2000000 },
];

const availabelPlans = [
	"free_plan",
	"growth_plan",
	"custom_plan",
	"business_plan",
	"enterprise_plan",
	"free_trial_plan",
	"agency_plan",
];

var PROMO_CODE_BOTH = 0;
var PROMO_CODE_ONLY_MONTHLY = 1;
var PROMO_CODE_ONLY_YEARLY = 2;

const monthlyBusinessPriceAmounts = [
	999, 1999, 3999, 5999, 7999, 11999, 14999, 17999, 18999, 19999, 20999, 22999,
	27999, 29999, 31999, 35999, 39999, 44999, 49999, 54999, 59999, 64999, 79999,
	89999, 99999, 119999, 139999, 159999, 199999, 249999, 299999, 399999, 499999,
];

const toInt = (n) => {
	return Math.round(Number(n));
};

const isDiscountAvailabel = computed(() => {
	if (isMonthlyBillingType.value && monthlyContactDiscount.value > 0) {
		return true;
	} else if (!isMonthlyBillingType.value && yearlyContactDiscount.value > 0) {
		return true;
	} else {
		return false;
	}
});

const discount = computed(() => {
	if (isMonthlyBillingType.value && monthlyContactDiscount.value > 0) {
		return monthlyContactDiscount.value;
	} else if (!isMonthlyBillingType.value && yearlyContactDiscount.value > 0) {
		return yearlyContactDiscount.value;
	} else {
		return 0;
	}
});

const toggleBillingType = () => {
	isMonthlyBillingType.value = !isMonthlyBillingType.value;
	let planDuration = "Monthly";
	if (!isMonthlyBillingType.value) {
		planDuration = "Yearly";
		totalAmount.value = 12 * businessPriceAmount.value;
		totalAmount.value = totalAmount.value * ((100 - discount.value) / 100);
	} else {
		totalAmount.value = businessPriceAmount.value;
	}
	const newLP = {
		...settingStore.$state.paymentForm,
		planDuration: planDuration,
		price: totalAmount.value.toString(),
	};
	settingStore.setResource({ resource: "paymentForm", value: newLP });
};

const handleDropdownInput = (e) => {
	selectedOption.value = e;

	description.value =
		"Business Plan ( " +
		options[selectedOption.value].contact +
		" contacts )";

	if (isMonthlyBillingType.value) {
		businessPriceAmount.value =
			monthlyBusinessPriceAmounts[selectedOption.value] -
			toInt(
				(monthlyContactDiscount.value *
					monthlyBusinessPriceAmounts[selectedOption.value]) /
					100
			);
		totalAmount.value = businessPriceAmount.value;
	} else {
		businessPriceAmount.value =
			(monthlyBusinessPriceAmounts[selectedOption.value] * 12 -
				toInt(
					(yearlyContactDiscount.value *
						(monthlyBusinessPriceAmounts[selectedOption.value] * 12)) /
						100
				)) /
			12;
		totalAmount.value = 12 * businessPriceAmount.value;
	}

	const newLP = {
		...settingStore.$state.paymentForm,
		contacts: options[selectedOption.value].contact.toString(),
		price: totalAmount.value.toString(),
	};
	settingStore.setResource({ resource: "paymentForm", value: newLP });
};

const selectedMonthlyPrice = computed(() => {
	if (selectedOption.value >= 0 && selectedOption.value < options.length) {
		let amount =
			monthlyBusinessPriceAmounts[selectedOption.value] -
			toInt(
				(monthlyContactDiscount.value *
					monthlyBusinessPriceAmounts[selectedOption.value]) /
					100
			);
		return parseFloat(amount / 100.0).toFixed(2);
	}
	return "...";
});

const selectedPrice = computed(() => {
	if (selectedOption.value >= 0 && selectedOption.value < options.length) {
		let amount = 0;
		if (isMonthlyBillingType.value) {
			amount =
				monthlyBusinessPriceAmounts[selectedOption.value] -
				toInt(
					(monthlyContactDiscount.value *
						monthlyBusinessPriceAmounts[selectedOption.value]) /
						100
				);
		} else {
			amount =
				(monthlyBusinessPriceAmounts[selectedOption.value] * 12 -
					toInt(
						(yearlyContactDiscount.value *
							(monthlyBusinessPriceAmounts[selectedOption.value] * 12)) /
							100
					)) /
				12;
		}
		return parseFloat(amount / 100.0).toFixed(2);
	}
	return "...";
});

const getCurrentPlan = computed(() => {
	const tier = store.$state?.authInfo?.account?.Tier ?? 0;
	return availabelPlans[tier];
});

const getCurrentBillingDuration = computed(() => {
	return settingStore.$state.billingDuration;
});

const getCookieValue = (name) => {
	const match = cookies.value.match(new RegExp("(^|; )" + name + "=([^;]*)"));
	return match ? decodeURIComponent(match[2]) : null;
};

//          -----   stripe code   -----
var paymentHandler = StripeCheckout.configure({
	key: stripePublicKey(),
	image: `/static_source/img/apple-icon-180x180.png`, // rendering from frontend public resource
	locale: "auto",
	token: function (token) {
		// You can access the token ID with `token.id`.
		// Get the token ID to your server-side code for use.
		store.globalSpinnerStart();
		const newLP = {
			...settingStore.$state.paymentForm,
			stripeToken: token.id,
			hutk: getCookieValue("hubspotutk"),
		};
		settingStore.setResource({ resource: "paymentForm", value: newLP });

		settingStore
			.purchasePlan()
			.then((data) => {
				store.globalSpinnerStop();
				toast("Successfully Upgraded", {
					theme: "auto",
					type: "success",
					hideProgressBar: false,
					transition: "slide",
				});
			})
			.catch((err) => {
				console.log("some error occured", err);
				store.globalSpinnerStop();
				toast("Payment Failed", {
					theme: "auto",
					type: "error",
					hideProgressBar: false,
					transition: "slide",
				});
			});
	},
});

const handlePurchase = () => {
	isComponentModalActive.value = false;
	initStripePayment();
};

const cancelModalClicked = () => {
	isComponentModalActive.value = false;
};

const upgradeClicked = async () => {
	settingStore.resetPaymentForm();
	await settingStore.getBillingState();

	// Populate params according to account state
	if (settingStore.$state.accountState !== "") {
		if (settingStore.$state.accountState === AccountState.PaidSubscribed) {
			label.value = "Update Card";
			paymentType.value = "Update";
			totalAmount.value = 50;
			description.value = "Update Card";
		} else if (
			settingStore.$state.accountState === AccountState.CardInvalid
		) {
			label.value = "Pay Due + ";
			paymentType.value = "Update";
			totalAmount.value = 50;
			description.value = "Update Card - previously invalid";
		} else {
			label.value = "Upgrade for";
			paymentType.value = "Upgrade";
			totalAmount.value = 999;
			description.value = "Business Plan (1000 contacts )";
		}

		selectedOption.value = 0;
		isMonthlyBillingType.value = true;
	} else {
		//toast info
		toast("Unable to fetch Account state. Reload the page!", {
			theme: "auto",
			type: "error",
			hideProgressBar: false,
			transition: "slide",
		});
		return;
	}

	//Populate Discount Params
	const promoCode = store.$state?.authInfo?.account?.PromoCode ?? "";
	if (promoCode === "") {
		monthlyContactDiscount.value = 0;
		yearlyContactDiscount.value = 25;
		yearlyDefaultDiscount.value = true;
	} else if (
		settingStore.$state.promotionDurationApplicable === PROMO_CODE_BOTH
	) {
		monthlyContactDiscount.value =
			settingStore.$state.contactDiscountPercentage;
		yearlyContactDiscount.value =
			settingStore.$state.contactDiscountPercentage;
		yearlyDefaultDiscount.value = false;
	} else if (
		settingStore.$state.promotionDurationApplicable ===
		PROMO_CODE_ONLY_MONTHLY
	) {
		monthlyContactDiscount.value =
			settingStore.$state.contactDiscountPercentage;
		yearlyContactDiscount.value = 25;
		yearlyDefaultDiscount.value = true;
	} else if (
		settingStore.$state.promotionDurationApplicable === PROMO_CODE_ONLY_YEARLY
	) {
		monthlyContactDiscount.value = 0;
		yearlyContactDiscount.value =
			settingStore.$state.contactDiscountPercentage;
		yearlyDefaultDiscount.value = false;
	}

	// If card is invalid ask to update
	if (settingStore.$state.accountState === AccountState.CardInvalid) {
		initStripePayment();
		return;
	}

	isComponentModalActive.value = true;
	await settingStore.upgradeClicked();
};

const initStripePayment = () => {
	const email = store.$state?.authInfo?.account?.Email ?? "";
	paymentHandler.open({
		name: "SendX Inc.",
		description: description.value,
		zipCode: true,
		amount: totalAmount.value,
		panelLabel: label.value,
		email: email,
		allowRememberMe: false,
	});
};

window.addEventListener("popstate", function () {
	paymentHandler.close();
});

const resetData = async () => {
	store.globalSpinnerStart();
	await Promise.all([
		settingStore.getBillingState(),
		store.fetchAllMemberTeams().then((res) => {
			for (var i = 0; i < res.length; i++) {
				if (res[i].id === store.$state.currentTeam?.id) {
					activeTeam.value = res[i];
				}
			}
		}),
	]);
	store.globalSpinnerStop();
};

const timezones = computed(() => {
	const tzs = [];
	let timezoneEntities = timeZoneGroups.value;
	for (let i = 0; i < timezoneEntities.length; i++) {
		for (let j = 0; j < timezoneEntities[i].options.length; j++) {
			tzs.push(timezoneEntities[i].options[j]);
		}
	}

	return tzs;
});

const timeZoneGroups = computed(() => {
	return getTimeZoneGroups(false).map((group) => {
		const options = group.zones.reduce((options, zone) => {
			const info = getTimeZoneInfo(zone, now);

			if (!info) {
				return options;
			}

			let country = { code: "", name: "" };
			if (info.countries.length > 0) country = info.countries[0];

			options.push({
				label: info.name,
				value: info.zone,
				country,
				abbreviation: info.abbreviation,
				searchIndex: getSearchIndex(info, now),
				utc: formatUtcOffset(now, info.zone),
			});

			return options;
		}, []);

		return {
			label: group.name,
			options,
		};
	});
});

const getSearchIndex = (info, timestamp) => {
	const parts = [
		toLower(info.name),
		toLower(info.abbreviation),
		toLower(formatUtcOffset(timestamp, info.zone)),
	];

	for (const country of info.countries) {
		parts.push(toLower(country.name));
		parts.push(toLower(country.code));
	}

	return parts.join("|");
};

const getCurrentTimezone = computed(() => {
	const offsetMinutes = moment().utcOffset();
	const offsetHours = offsetMinutes / 60;
	const sign = offsetMinutes >= 0 ? "+" : "-";
	const formattedOffset = `UTC${sign}${
		Math.abs(offsetHours) % 1 === 0
			? Math.abs(offsetHours)
			: Math.abs(offsetHours).toFixed(1)
	}`;

	for (let i = 0; i < timezones.value.length; i++) {
		const opt = timezones.value[i];
		if (opt.utc === formattedOffset) {
			return opt.label;
		}
	}

	return "Atlantic/Reykjavik";
});

const handleDefaultTimezoneUpdate = async () => {
	try {
		await store.updateDefaultTimezone({
			tid: store.$state.currentTeam.id,
			timezone: getCurrentTimezone.value,
		});
	} catch (error) {
		console.log(
			"Error occurred while updating your default timezone : ",
			error
		);
	}
};

onMounted(async () => {
	cookies.value = document.cookie;
	resetData();

	if (!window.Beamer) {
		console.log("Beamer loading...");
	}

	if (store.$state.currentTeam?.defaultTimeZone === "Atlantic/Reykjavik") {
		await handleDefaultTimezoneUpdate();
	}
});
</script>

<style scoped>
.active-tab:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.active-tab:focus {
	background-color: rgba(0, 0, 0, 0.2);
}

/*.nuxt-link-active.active-tab {*/
/*  background-color: rgba(0, 0, 0, 0.2);*/
/*}*/
</style>
