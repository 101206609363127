<template>
	<div class="fixed w-screen h-screen bg-black/30 z-50 flex justify-center">
		<div
			v-click-outside="closeCustomization"
			class="p-[25px] relative w-[800px] h-[80vh] rounded-lg bg-white mt-[50px] overflow-y-auto">
			<div class="flex flex-col gap-2 mb-4">
				<div class="flex flex-row justify-between items-center">
					<h1 class="text-[25px] text-gray-900 font-bold">
						Customize Shortcuts
					</h1>
				</div>
				<h1 class="text-sm text-gray-500">
					To customize shortcut keys, click on the shortcut field and press
					your desired keys one after another.
				</h1>
			</div>
			<div
				class="absolute cursor-pointer top-2 right-2"
				@click.stop="closeCustomization">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="text-gray-600 hover:text-gray-800 h-5 w-5"
					viewBox="0 0 20 20"
					fill="currentColor">
					<path
						fill-rule="evenodd"
						d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
						clip-rule="evenodd" />
				</svg>
			</div>
			<div class="mb-4">
				<Button
					@click="resetToDefault"
					size="sm"
					>RESET TO DEFAULT</Button
				>
			</div>
			<div class="flex flex-col">
				<!-- General Shortcuts -->
				<div>
					<h2 class="text-lg font-medium mb-2">General</h2>
					<div class="flex flex-col gap-2">
						<div
							v-for="item in generalShortcuts"
							:key="item.title">
							<div>
								<div class="flex flex-row justify-between items-center">
									<div>
										<h1 class="text-sm text-gray-800 font-light">
											{{ item.title }}
										</h1>
									</div>
									<div class="flex flex-row gap-2 items-center">
										<VTooltip
											v-if="checkDuplicate(item.keys, item.title)">
											<div
												class="w-[15px] h-[15px] rounded-full bg-red-300 flex flex-row items-center justify-center">
												<h1
													class="cursor-default text-[10px] text-white">
													i
												</h1>
											</div>
											<template #popper>
												<div
													class="text-xs max-w-56 overflow-scroll">
													There are duplicate entries
												</div>
											</template>
										</VTooltip>
										<div
											tabindex="0"
											@click="handleDivFocus(item.title, item.keys)"
											:class="[
												'outline-none relative h-[40px] flex flex-row items-center gap-1 cursor-pointer rounded px-2 py-[2px] shortcut-input min-h-30px',
												activeTitle === item.title
													? 'border border-solid border-blue-500'
													: 'border border-solid border-gray-100',
											]">
											<span
												class="text-[10px] flex flex-row items-center justify-center text-gray-400"
												v-if="item.keys.length === 0"
												>Enter Keyboard Shortcut</span
											>

											<span
												v-for="(key, ind) in item.keys"
												class="shortcut-key-letter">
												{{
													key === "Meta"
														? $device.isMacOS
															? CMD
															: CTRL
														: key === "Control"
														? CTRL
														: key
												}}
											</span>
											<div
												v-if="activeTitle === item.title"
												class="z-10 flex flex-col ml-4 gap-[2px] mb-[1px]">
												<button
													@click.stop="handleSave(false)"
													v-if="item.keys.length > 0"
													class="w-[12px] h-[12px] cursor-pointer bg-emerald-300 flex items-center justify-center rounded-[1px]">
													<h1 class="text-xs text-white">✓</h1>
												</button>
												<button
													@click.stop="
														resetSpecificKey(item.title)
													"
													class="w-[12px] h-[12px] cursor-pointer bg-red-300 flex items-center justify-center rounded-[1px]">
													<h1 class="text-xs text-white">×</h1>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="generalShortcuts.length === 0">
							<h1 class="text-sm text-gray-600">
								No general shortcut found.
							</h1>
						</div>
					</div>
				</div>

				<div>
					<h2 class="text-lg font-medium mb-2 mt-8">Navigation</h2>
					<!-- {{ ShortcutMap }} -->
					<div class="flex flex-col gap-2">
						<div
							v-for="item in navigationShortcuts"
							:key="item.title">
							<div>
								<div class="flex flex-row justify-between items-center">
									<div>
										<h1 class="text-sm text-gray-800 font-light">
											{{ item.title }}
										</h1>
									</div>
									<div class="flex flex-row gap-2 items-center">
										<VTooltip
											v-if="checkDuplicate(item.keys, item.title)">
											<div
												class="w-[15px] h-[15px] rounded-full bg-red-300 flex flex-row items-center justify-center">
												<h1
													class="cursor-default text-[10px] text-white">
													i
												</h1>
											</div>
											<template #popper>
												<div
													class="text-xs max-w-56 overflow-scroll">
													There are duplicate entries
												</div>
											</template>
										</VTooltip>
										<div
											tabindex="0"
											@click="handleDivFocus(item.title, item.keys)"
											:class="[
												'outline-none relative h-[40px] flex flex-row items-center gap-1 cursor-pointer rounded px-2 py-[2px] shortcut-input min-h-30px',
												activeTitle === item.title
													? 'border border-solid border-blue-500'
													: 'border border-solid border-gray-100',
											]">
											<span
												class="text-[10px] flex flex-row items-center justify-center text-gray-400"
												v-if="item.keys.length === 0"
												>Enter Keyboard Shortcut</span
											>

											<span
												v-for="(key, ind) in item.keys"
												class="shortcut-key-letter">
												{{
													key === "Meta"
														? $device.isMacOS
															? CMD
															: CTRL
														: key
												}}
											</span>
											<div
												v-if="activeTitle === item.title"
												class="z-10 flex flex-col ml-4 gap-[2px] mb-[1px]">
												<button
													@click.stop="handleSave(false)"
													v-if="item.keys.length > 0"
													class="w-[12px] h-[12px] cursor-pointer bg-emerald-300 flex items-center justify-center rounded-[1px]">
													<h1 class="text-xs text-white">✓</h1>
												</button>
												<button
													@click.stop="
														resetSpecificKey(item.title)
													"
													class="w-[12px] h-[12px] cursor-pointer bg-red-300 flex items-center justify-center rounded-[1px]">
													<h1 class="text-xs text-white">×</h1>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="navigationShortcuts.length === 0">
							<h1 class="text-sm text-gray-600">
								No navigation shortcut found.
							</h1>
						</div>
					</div>
				</div>

				<!-- Creation Shortcuts -->
				<div>
					<h2 class="text-lg font-medium mb-2 mt-8">Creation</h2>
					<div class="flex flex-col gap-2">
						<div
							v-for="item in creationShortcuts"
							:key="item.title">
							<div>
								<div class="flex flex-row justify-between items-center">
									<div>
										<h1 class="text-sm text-gray-800 font-light">
											{{ item.title }}
										</h1>
									</div>
									<div class="flex flex-row gap-2 items-center">
										<VTooltip
											v-if="checkDuplicate(item.keys, item.title)">
											<div
												class="w-[15px] h-[15px] rounded-full bg-red-300 flex flex-row items-center justify-center">
												<h1
													class="cursor-default text-[10px] text-white">
													i
												</h1>
											</div>
											<template #popper>
												<div
													class="text-xs max-w-56 overflow-scroll">
													There are duplicate entries
												</div>
											</template>
										</VTooltip>
										<div
											tabindex="0"
											@click="handleDivFocus(item.title, item.keys)"
											:class="[
												'outline-none relative h-[40px] flex flex-row items-center gap-1 cursor-pointer rounded px-2 py-[2px] shortcut-input min-h-30px',
												activeTitle === item.title
													? 'border border-solid border-blue-500'
													: 'border border-solid border-gray-100',
											]">
											<span
												class="text-[10px] flex flex-row items-center justify-center text-gray-400"
												v-if="item.keys.length === 0"
												>Enter Keyboard Shortcut</span
											>

											<span
												v-for="(key, ind) in item.keys"
												class="shortcut-key-letter">
												{{
													key === "Meta"
														? $device.isMacOS
															? CMD
															: CTRL
														: key
												}}
											</span>
											<div
												v-if="activeTitle === item.title"
												class="z-10 flex flex-col ml-4 gap-[2px] mb-[1px]">
												<button
													@click.stop="handleSave(false)"
													v-if="item.keys.length > 0"
													class="w-[12px] h-[12px] cursor-pointer bg-emerald-300 flex items-center justify-center rounded-[1px]">
													<h1 class="text-xs text-white">✓</h1>
												</button>
												<button
													@click.stop="
														resetSpecificKey(item.title)
													"
													class="w-[12px] h-[12px] cursor-pointer bg-red-300 flex items-center justify-center rounded-[1px]">
													<h1 class="text-xs text-white">×</h1>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="creationShortcuts.length === 0">
							<h1 class="text-sm text-gray-600">
								No creation shortcut found.
							</h1>
						</div>
					</div>
				</div>
			</div>

			<!-- <div class="mt-10 flex flex-row justify-end">
				<Button
					@click="handleSave"
					class="w-[100px]"
					type="primary"
					size="lg"
					>Save</Button
				>
			</div> -->
		</div>
	</div>
</template>
<script setup>
import { CMD, CTRL, Routes, DEFAULT_SHORTCUTS } from "~/constants";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const { isMacOS, isWindows } = useDevice();
const activeTitle = ref("");
const store = useGlobalStore();

const ShortcutMap = computed({
	get() {
		return [...JSON.parse(store.$state.shortCutkeys)];
	},
	set(newShortcutKeys) {
		store.setResource({
			resource: "shortCutkeys",
			value: JSON.stringify(newShortcutKeys),
		});
	},
});

const emit = defineEmits(["closeCustomization"]);

const closeCustomization = () => {
	emit("closeCustomization");
};

const activeKeys = ref([]);

const handleDivFocus = (title, keys) => {
	const ind = ShortcutMap.value.findIndex(
		(item) => item.title === activeTitle.value
	);
	console.log(ind);
	if (ind !== -1) {
		console.log("inside -1 handleDivFocus");
		const tempShortcutKeys = [...ShortcutMap.value];
		tempShortcutKeys[ind].keys = activeKeys.value;
		ShortcutMap.value = tempShortcutKeys;
	}

	activeTitle.value = title;
	activeKeys.value = keys;
	const index = ShortcutMap.value.findIndex((item) => item.title === title);
	const tempShortcutKeys = [...ShortcutMap.value];
	if (index !== -1) {
		tempShortcutKeys[index].keys = [];
		ShortcutMap.value = tempShortcutKeys;
	}
};

const resetSpecificKey = (title) => {
	console.log("resetSpecificKey called");
	const index = ShortcutMap.value.findIndex((item) => item.title === title);
	console.log(index);
	if (index !== -1) {
		console.log("inside -1 specific");
		const tempShortcutKeys = [...ShortcutMap.value];
		tempShortcutKeys[index].keys = activeKeys.value;
		ShortcutMap.value = tempShortcutKeys;
	}
	activeTitle.value = "";
};

// const handleDivBlur = (title) => {
// 	console.log("handleBlur called");
// 	activeTitle.value = "";
// 	const index = ShortcutMap.value.findIndex((item) => item.title === title);
// 	const tempShortcutKeys = [...ShortcutMap.value];

// 	if (index !== -1) {
// 		if (tempShortcutKeys[index].keys.length === 0) {
// 			tempShortcutKeys[index].keys = activeKeys.value;
// 			ShortcutMap.value = tempShortcutKeys;
// 			return;
// 		}
// 	}
// };

const resetToDefault = async () => {
	ShortcutMap.value = [...DEFAULT_SHORTCUTS];
	try {
		await store.updateShortcutKeys({ shortcuts: ShortcutMap.value });
		toast("Keyboard shortcuts reset to default", {
			theme: "auto",
			type: "success",
			hideProgressBar: false,
			transition: "slide",
		});
	} catch (error) {
		toast("Error resetting keyboard shortcuts", {
			theme: "auto",
			type: "error",
			hideProgressBar: false,
			transition: "slide",
		});
	}
	activeTitle.value = "";
};

const navigationShortcuts = computed(() =>
	ShortcutMap.value.filter(
		(item) =>
			item.title.startsWith("Go to") &&
			!["Go to Tickets", "Go to Settings"].includes(item.title) // Exclude specific "Go to" items
	)
);

const creationShortcuts = computed(() =>
	ShortcutMap.value.filter(
		(item) => item.title.startsWith("New") && item.title !== "New Ticket" // Exclude "New Ticket"
	)
);

const generalShortcuts = computed(() =>
	ShortcutMap.value.filter(
		(item) =>
			!navigationShortcuts.value.includes(item) &&
			!creationShortcuts.value.includes(item)
	)
);

const checkDuplicate = computed(() => {
	return (keys, title) => {
		const keyString = keys.join("+");
		return ShortcutMap.value.some(
			(shortcut) =>
				shortcut.keys.join("+") === keyString && shortcut.title !== title
		);
	};
});

const hasDuplicateShortcuts = () => {
	const seenShortcuts = new Set();

	return ShortcutMap.value.some((shortcut) => {
		const keyString = shortcut.keys.join("+");

		if (seenShortcuts.has(keyString)) {
			return true;
		}

		seenShortcuts.add(keyString);
		return false;
	});
};

const updateShortcut = (title, key) => {
	const index = ShortcutMap.value.findIndex((item) => item.title === title);
	const tempShortcutKeys = [...ShortcutMap.value];
	if (index !== -1) {
		tempShortcutKeys[index].keys = [...tempShortcutKeys[index].keys, key];
		ShortcutMap.value = tempShortcutKeys;
	}
};

const changeShortcutKeys = (pressedKey) => {
	updateShortcut(activeTitle.value, pressedKey);
};

const resetKeys = (key) => {
	console.log("Updating key combination:");
	changeShortcutKeys(key);
};

const handleKeyDownInCustomize = (event) => {
	console.log(event.key);
	if (
		event.target.tagName === "INPUT" ||
		event.target.tagName === "TEXTAREA" ||
		event.target.isContentEditable
	) {
		console.log("inside if customize 3");
		return;
	}
	if (event.key === "Control" && isWindows) {
		resetKeys("Meta");
	} else {
		resetKeys(event.key);
	}
};

onMounted(() => {
	window.addEventListener("keydown", handleKeyDownInCustomize);
});

onUnmounted(() => {
	window.removeEventListener("keydown", handleKeyDownInCustomize);
});

const handleSave = async (close) => {
	console.log("handleSave called");
	if (hasDuplicateShortcuts()) {
		toast("There are duplicate shortcut keys", {
			theme: "auto",
			type: "error",
			hideProgressBar: false,
			transition: "slide",
		});
	} else {
		try {
			const index = ShortcutMap.value.findIndex(
				(item) => item.title === activeTitle.value
			);
			const tempShortcutKeys = [...ShortcutMap.value];

			if (index !== -1 && tempShortcutKeys[index].keys.length === 0) {
				tempShortcutKeys[index].keys = activeKeys.value;
				ShortcutMap.value = tempShortcutKeys;
				await store.updateShortcutKeys({ shortcuts: ShortcutMap.value });
				return;
			}

			await store.updateShortcutKeys({ shortcuts: ShortcutMap.value });
			toast("Keyboard shortcuts successfully updated", {
				theme: "auto",
				type: "success",
				hideProgressBar: false,
				transition: "slide",
			});
			activeTitle.value = "";
		} catch (error) {
			console.log(error);
			toast("Error updating keyboard shortcuts", {
				theme: "auto",
				type: "error",
				hideProgressBar: false,
				transition: "slide",
			});
		}
		if (close) closeCustomization();
	}
};
</script>

<style lang="css">
.shortcut-key-letter {
	display: inline-block;
	text-align: center;
	border-radius: 2px;
	font-size: 10px;
	padding: 2px;
	min-width: 20px;
	height: 20px;
	background-color: rgb(201, 201, 201);
	color: rgb(51, 51, 51);
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 2px 0px;
}
</style>
