<template>
	<div>
		<div
			class="flex flex-row justify-between items-center">
			<h1 class="text-sm text-gray-800">
				{{ item.title }}
			</h1>
			<div class="flex flex-row gap-1 items-center">
				<div v-for="key, ind in item.keys">
					<div
						class="flex flex-row gap-1 items-center">
						<div
							class="rounded px-1.5 py-[4px] bg-white shadow">
							<h1 class="text-[10px] text-gray-800">
							{{ key === 'Meta' ? ($device.isMacOS ? CMD : CTRL) : key.toUpperCase()}}
						</h1>
						</div>
						<h1
							v-if="item.keys.length - 1 !== ind"
							class="text-[10px]">
							then
						</h1>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { CMD, CTRL } from '~/constants';


const { isMacOS, isWindows } = useDevice();

const props = defineProps({
	item: {
		type: Object,
	},
});

</script>
