<template>
	<Transition
		enter-active-class="transform transition-all ease-in-out duration-500"
		enter-from-class="-translate-x-full"
		enter-to-class="translate-x-0"
		leave-active-class="transform transition-all ease-in-out duration-500"
		leave-from-class="translate-x-0"
		leave-to-class="-translate-x-full">
		<div
			v-click-outside="handleClose"
			v-show="open"
			class="z-[100] max-w-full w-[400px] h-screen top-0 fixed">
			<div class="h-full bg-gray-100 shadow-xl overflow-y-auto">
				<div>
					<div>
						<div
							class="bg-white shadow flex flex-row items-center justify-between py-4 px-5">
							<div class="text-lg font-semibold text-gray-700">
								Shortcuts
							</div>
							<div
								class="cursor-pointer"
								@click.stop="handleClose">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="text-gray-600 hover:text-gray-800 h-5 w-5"
									viewBox="0 0 20 20"
									fill="currentColor">
									<path
										fill-rule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clip-rule="evenodd" />
								</svg>
							</div>
						</div>
						<div class="pt-4 px-4 flex flex-row gap-1">
							<div class="w-[75%]">
							<Input :show-icon="true" :value="searchQuery" @input="handleSearch"/>
						</div>
							<div class="w-[25%]">
								<Button @click="openCustomization" class="w-full" size="lg" type="primary">Customize</Button>
							</div>
						</div>
						<div class="p-5 flex flex-col gap-4">

							<div>
								<h2 class="text-lg font-bold mb-2">General</h2>
								<div class="flex flex-col gap-2">
									<div
										v-for="item in generalShortcuts"
										:key="item.title">
										<KeyboardShortcutItem :item="item" />
									</div>
									<div v-if="generalShortcuts.length === 0">
										<h1 class="text-sm text-gray-600">No general shortcut found.</h1>
									</div>
								</div>
							</div>

							<div>
								<h2 class="text-lg font-bold mb-2">Navigation</h2>
								<div class="flex flex-col gap-2">
									<div
										v-for="item in navigationShortcuts"
										:key="item.title">
										<KeyboardShortcutItem :item="item" />
									</div>
									<div v-if="navigationShortcuts.length === 0">
										<h1 class="text-sm text-gray-600">No navigation shortcut found.</h1>
									</div>
								</div>
							</div>

							<!-- Creation Shortcuts -->
							<div>
								<h2 class="text-lg font-bold mb-2">Creation</h2>
								<div class="flex flex-col gap-2">
									<div
										v-for="item in creationShortcuts"
										:key="item.title">
										<KeyboardShortcutItem :item="item" />
									</div>
									<div v-if="creationShortcuts.length === 0">
										<h1 class="text-sm text-gray-600">No creation shortcut found.</h1>
									</div>
								</div>
							</div>

							<!-- General Shortcuts -->
							
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</Transition>
</template>

<style>
.cls-1 {
	fill: none;
}
</style>

<script setup>
import { Routes } from "~/constants";

const props = defineProps({
	open: {
		type: Boolean,
	},
});

const { isMacOS, isWindows } = useDevice();
const store = useGlobalStore()

const ShortcutMap = ref([...JSON.parse(store.$state.shortCutkeys)]);

watch(() => store.$state.shortCutkeys, () => {
	ShortcutMap.value = JSON.parse(store.$state.shortCutkeys);
})

const searchQuery = ref(""); // Reactive search input

const handleSearch = (e) => {
	searchQuery.value = e.target.value
}

const filteredShortcuts = computed(() => {
	return ShortcutMap.value.filter((item) =>
		item.title.toLowerCase().includes(searchQuery.value.toLowerCase())
	);
});

const navigationShortcuts = computed(() =>
  filteredShortcuts.value.filter(
    (item) =>
      item.title.startsWith("Go to") &&
      !["Go to Tickets", "Go to Settings"].includes(item.title) // Exclude specific "Go to" items
  )
);

const creationShortcuts = computed(() =>
  filteredShortcuts.value.filter(
    (item) =>
      item.title.startsWith("New") &&
      item.title !== "New Ticket" // Exclude "New Ticket"
  )
);

const generalShortcuts = computed(() =>
  filteredShortcuts.value.filter(
    (item) =>
      !navigationShortcuts.value.includes(item) &&
      !creationShortcuts.value.includes(item)
  )
);

const emit = defineEmits(["close", "openCustomization"]);

const handleClose = async () => {
	emit("close");
};

const openCustomization = () => {
	emit('openCustomization');
	handleClose()
}

</script>
