<template>
	<div class="mt-[20px]">
		<div class="flex flex-row gap-[30px]">
			<div
				class="w-[30%]"
				v-if="!isTyping">
				<AisRefinementList
					show-more
					:sort-by="['name:asc', 'count:desc']"
					attribute="categories">
					<template
						v-slot="{
							items,
							isShowingMore,
							isFromSearch,
							canToggleShowMore,
							refine,
							createURL,
							toggleShowMore,
							searchForItems,
							sendEvent,
						}">
						<div>
							<ul class="overflow-y-scroll max-h-[500px]">
								<li
									class="text-sm w-full py-[10px] px-[15px] rounded-md"
									v-for="item in items"
									:style="{
										backgroundColor: item.isRefined ? '#F3F4F6' : '',
									}"
									:key="item.value">
									<a
										class="flex flex-row justify-between"
										:href="createURL(item)"
										@click.prevent="refine(item.value)">
										<span>{{ item.value }}</span>
									</a>
								</li>
							</ul>
							<button
								class="text-sm text-center w-full py-[10px] px-[15px] mt-[10px]"
								@click="toggleShowMore"
								:disabled="!canToggleShowMore">
								{{ !isShowingMore ? "Show more" : "Show less" }}
							</button>
						</div>
					</template>
				</AisRefinementList>
			</div>
			<div :class="[!isTyping ? 'w-[70%]' : 'w-full']">
				<div class="overflow-y-scroll">
					<div class="mb-[15px]">
						<h1
							v-if="!isTyping"
							class="text-[#6B7280] text-sm font-normal">
							Video tutorials
						</h1>
					</div>
					<div class="flex flex-col gap-[15px]">
						<AisHits class="h-[500px]">
							<template v-slot="{ items }">
								<div
									v-if="items.length === 0"
									class="flex flex-col items-center justify-center mt-[30px]">
									<h1 class="text-sm text-[#111827]">
										Enter full email
									</h1>
									<h1
										class="text-xs text-[#6B7280] w-[300px] text-center">
										or try different keywords, check for any possible
										typos or reach out to
										<span class="text-blue-500">support</span>
									</h1>
								</div>
								<div v-else>
									<div class="mb-[15px]">
										<h1
											v-if="isTyping"
											class="text-[#6B7280] text-sm font-normal">
											{{ items.length }} search results
										</h1>
									</div>
									<div class="grid gap-[20px]" :class="[!isTyping ? 'grid-cols-3' : 'grid-cols-3']">
										<div v-for="item in items" class="col-span-1 flex flex-col gap-2">
											<div @click="handlePlayVideo(item)" class="cursor-pointer">
												<div class="flex justify-center items-center overflow-hidden rounded-md">
													<div v-if="showVideoLoader" id="loader" class="w-full h-full bg-gray-100 animate-pulse"></div>
													<iframe :src="getUrl(item.src)" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="autoplay; fullscreen" @load="hideLoader" class=" flex overflow-hidden pointer-events-none"></iframe>
												</div>
											</div>

											<div class="flex flex-col gap-[5px]">
												<div
													class="flex flex-row gap-2 items-center text-xs text-[#6B7280]">
													<h1 class="text-xs">
														{{ item.views }} Views
													</h1>
													<div
														class="h-[3px] w-[3px] rounded-full bg-[#6B7280]"></div>
													<h1>{{ item.daysAgo }} days ago</h1>
												</div>
												<div>
													<h1 class="text-sm text-[#111827] text-wrap">
														{{ item.title }}
													</h1>
												</div>
												<div class="flex justify-between items-center pr-2">
													<div class="flex items-center gap-2">
														<svg
															width="12"
															height="13"
															viewBox="0 0 12 13"
															fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path
																d="M10.875 6.5C10.875 4.76562 9.9375 3.17188 8.4375 2.28125C6.91406 1.41406 5.0625 1.41406 3.5625 2.28125C2.03906 3.17188 1.125 4.76562 1.125 6.5C1.125 8.25781 2.03906 9.85156 3.5625 10.7422C5.0625 11.6094 6.91406 11.6094 8.4375 10.7422C9.9375 9.85156 10.875 8.25781 10.875 6.5ZM0 6.5C0 4.36719 1.125 2.39844 3 1.32031C4.85156 0.242188 7.125 0.242188 9 1.32031C10.8516 2.39844 12 4.36719 12 6.5C12 8.65625 10.8516 10.625 9 11.7031C7.125 12.7812 4.85156 12.7812 3 11.7031C1.125 10.625 0 8.65625 0 6.5ZM5.4375 3.3125C5.4375 3.00781 5.67188 2.75 6 2.75C6.30469 2.75 6.5625 3.00781 6.5625 3.3125V6.21875L8.55469 7.53125C8.8125 7.71875 8.88281 8.07031 8.71875 8.32812C8.53125 8.58594 8.17969 8.65625 7.92188 8.46875L5.67188 6.96875C5.53125 6.875 5.4375 6.6875 5.4375 6.5V3.3125Z"
																fill="#6B7280" />
														</svg>
														<h1 class="text-sm text-[#6B7280]">
															{{ item.duration }}
														</h1>
													</div>
													<div @click.stop="openVideo(item.src)" class="cursor-pointer">
														<svg width="14" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8.66406 0C8.11178 0 7.66406 0.447715 7.66406 1C7.66406 1.55228 8.11178 2 8.66406 2H11.2498L4.95696 8.29289C4.56643 8.68342 4.56643 9.31658 4.95696 9.70711C5.34748 10.0976 5.98065 10.0976 6.37117 9.70711L12.6641 3.41421V6C12.6641 6.55228 13.1118 7 13.6641 7C14.2163 7 14.6641 6.55228 14.6641 6V1C14.6641 0.447715 14.2163 0 13.6641 0H8.66406Z" fill="#6B7280" /> <path d="M2.66406 2C1.55949 2 0.664062 2.89543 0.664062 4V12C0.664062 13.1046 1.55949 14 2.66406 14H10.6641C11.7686 14 12.6641 13.1046 12.6641 12V9C12.6641 8.44772 12.2163 8 11.6641 8C11.1118 8 10.6641 8.44772 10.6641 9V12H2.66406V4L5.66406 4C6.21635 4 6.66406 3.55228 6.66406 3C6.66406 2.44772 6.21635 2 5.66406 2H2.66406Z" fill="#6B7280" /></svg>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</template>
						</AisHits>
						<AisConfigure
							:hits-per-page.camel="10"
							:distinct="true"
							:analytics="false"
							:enable-personalization.camel="true" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import {
	AisHits,
	AisRefinementList,
	AisConfigure,
} from "vue-instantsearch/vue3/es";

const showVideoLoader = ref(true);
const props = defineProps({
	isTyping: {
		default: false,
	},
});

const hideLoader = () => {
	console.log("loading finished");
	showVideoLoader.value = false;
};

const store = useGlobalStore();

const pushToSlack = async (src) => {
	console.log(src);
	try{
		const res = await store.sendSlackNotification({email: store.$state.authInfo.account.Email, title: src.title, url: src.src, from: "knowledge-base"});
	}catch(e){
		console.log(e);
	}
};

const handlePlayVideo = async (src) => {
	console.log("src here =>" , src)
	store.setResource({ resource: "showSearch", value: false });
	store.setResource({ resource: "showVideoPlayer", value: true });
	store.setResource({ resource: "selectedVideoDetails", value: src });
	await pushToSlack(src);
};

const getUrl = (url) => {
	const splitArr = url?.split("/");
	const videoId = splitArr[splitArr.length - 1];
	return "https://video.sendworks.com/share/" + videoId + "/embed";
};

const openVideo = (url) => {
	window.open(url, "_blank");
}
</script>
